import * as Types from '../../Constents/actionTypes';
  
const submitForm = (state = {}, action) => {
    //console.log(`state in auth is :`,state);
    switch (action.type) {
      
    case Types.ADD_NEW_TECHNOLOGY: 
      return {
        ...state,
        addedtechnology:action.payload
      }
      case Types.PROTECT_TECHNOLOGY: 
      return {
        ...state,
        techProtected:action.payload
      }
      case Types.TECHNOLOGY_SALE: 
      return {
        ...state,
        techSale:action.payload
      }
      case Types.PROTECT_BRAND: 
      return {
        ...state,
        brandProtected:action.payload
      }
      case Types.ADD_NEW_BRAND: 
      return {
        ...state,
        addedBrand:action.payload
      }
      case Types.BRAND_SALE: 
      return {
        ...state,
        brandSale:action.payload
      }
      default:
        return state;
    }
};
export default submitForm;