import axios from 'axios'
import {API_URL,API_REQUESTS} from '../Helpers/constant'

const loginInterface = {
    'email': 'email',
    'password' : 'password'
}

const getLoginParams = ({username,password}) => {
    return {
        [loginInterface['email']]: username,
        [loginInterface['password']] : password
    }
}

const Storage = window.localStorage

class Auth {
    static _data
    static refreshToken
    static userId   //We can have userId from cognitoTokens.
    static isUserAuthenticated
    static _rolesData

    static _clear() {
        this._data = undefined
        this.refreshToken = undefined
        this.token = undefined
        this.userId=undefined
        this.isUserAuthenticated = undefined
        this.userName = undefined
        this._rolesData = undefined
    }

    static initialize() {
        try {
            const data = JSON.parse(atob(Storage.getItem('data')))
            this.refreshToken = data.refreshToken
            this.userId = data.userId
            this.token = data.token
            this.userName = data.userName
            this._setDataFromJWTToken(this.token, this.userName)
            this._data = data
        } catch (e) {
            return undefined
        }
    }


   static _setDataFromJWTToken(token, userName) {
        
        // this.userId = payload['custom:userId']
        this.userName = userName
        this.isUserAuthenticated = true
    }

    static async loginWithUserNameAndPassword(args) {
        try {                                                                                                                                                                       
            let headers= {
                "Content-Type": "application/json"
            }
            const LOGIN_URL = `${API_URL}${API_REQUESTS.LOGIN}`
            const { data } = await axios.post(LOGIN_URL, getLoginParams(args), {headers})
            return this.afterLogin(data)

        } catch(e) {

            const response = e.response;
            if (response) {
                const data = response.data
                if(data) {
                    const message = data
                    // console.log(message);
                    throw new Error(message)
                } else {
                    throw new Error('Server Error')
                }
            } else {
                throw new Error('Server Error')
            }
        }
    }

    static logout() {
        this._clear()
        Storage.removeItem('data')
        Storage.removeItem('roles')
    }

    static get data() {
        if(!this._data) {
            this.initialize()
        }
        return this._data
    }
    // static get userRolesData() {
    //     if(!this._rolesData) {
    //         this.initializeRoles()
    //     }
    //     return this._rolesData
    // }

    //setTokens after LogIn
    static afterLogin(data) {
        // console.log(`after login data:`,data)
        // const accessToken = data['access']
        // const appClientId = data['clientId']
        const userName = data.admin.firstName
        const refreshToken = data.tokens.refresh.token
        const token = data.tokens.access.token
        const userId = data.admin._id

        // this.refreshToken = refreshToken
        this.userId = userId
        this.userName = userName
    
        const authData = {
            // accessToken,
            // appClientId,
            refreshToken,
            userName,
            token,
            userId
        }
        const userToken={
            userName,
            userId
        }
        this._setDataFromJWTToken(token, this.userName)

        Storage.setItem('data',btoa(JSON.stringify(authData)))
        return {
            // appClientId,
            token,
            refreshToken,
            userToken
        }
    }

    static setToken({ token }) {
        this._clear()
        
        const data = JSON.parse(atob(Storage.getItem('data')));
        data['token'] = token
        // data['accessToken'] = accessToken

        // this.accessToken = data['accessToken']
        this.refreshToken = data['refreshToken']
        this.token = data['token']
        this._setDataFromJWTToken(this.token)
        Storage.setItem('data', btoa(JSON.stringify(data)))
    }

    static _setAuthData({ token, refreshToken }) {
		this.token = token
		this.refreshToken = refreshToken
    }

    // static setRolesAndRights(roleData) {
    //     // this.roleIds = roleData.roleIds
    //     // this.rightObjects = roleData.rightObjects;
    //     // this.rights = roleData.rights;
    //     this._rolesData = roleData;
    //     Storage.setItem('roles', btoa(JSON.stringify(roleData)))
    // }

    // static initializeRoles() {
    //     try {
    //         const roleData = JSON.parse(atob(Storage.getItem('roles')))
    //         this.roleIds = roleData.roleIds
    //         this.rightObjects = roleData.rightObjects
    //         this.rights = roleData.rights
    //         this._rolesData = roleData
    //     } catch (e) {
    //         return undefined
    //     }
    // }


}
export default Auth;



