import * as Types from '../../Constents/actionTypes';
  
const auth = (state = {}, action) => {
    //console.log(`state in auth is :`,state);
    switch (action.type) {
      
    case Types.SET_TOKEN: 
      return {
        ...state,
        token: action.token,
        isUserAuthenticated: true
      }
    
    case Types.SET_REFRESH_TOKEN: 
        return {
          ...state,
          refreshToken:action.refreshToken,
        }
    case Types.USER_TOKEN: {
      return {
        ...state,
        userToken: action.userToken
      }
    }
    case Types.AUTH_LOGIN_ERROR: {
      return {
        ...state,
        authLoginError: action.authLoginError
      }
    }
    case Types.IS_USER_AUTHENTICATED: {
      return {
        ...state,
        isUserAuthenticated: action.isUserAuthenticated
      }
    }
    case Types.CLEAR: {
      return {
        ...state,
        authLoginError:undefined
      }
    }
    case Types.ON_LOGOUT:{
      delete state.refreshToken
      delete state.token
      return {
        ...state,
        isUserAuthenticated: false
      } 
    }
      default:
        return state;
    }
};
export default auth;