import React, { Fragment, useState } from 'react'
import {Link} from 'react-router-dom';
import {Button, Container } from 'reactstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShowCase from '../Showcase';
import './technology.scss'
import ListTechnology from './listTechnology';
import Protecttechnology from './protecttechnology';
import { connect } from "react-redux";
import TechnologySale from './technologySale';
import {REGISTRATION} from '../../Store/actions/users'

const mapStateToProps = (state) => ({
    isUserAuthenticated: state.auth.isUserAuthenticated
});

const mapDispatchToProps = (dispatch) => ({
    REGISTRATION:(payload)=> dispatch(REGISTRATION(payload)),
});

const Technology =(props)=>{
    const [isOpen, setIsOpen] = useState(false)
    const [protect, setProtect] = useState(false)
    const [isSale, setIsSale] = useState(false)
    // const [listAll, setListAll] = useState(false)

    const addlistTechnology = ()=>{
        setIsOpen(!isOpen)
    }
    const protectTechnology = ()=>{
        setProtect(!protect)
    }
    const saleTechnology = ()=>{
        setIsSale(!isSale)
    }
    return(
        <Fragment>
            <section className='tech-banner'>
            </section>
            <section className='content-wrapper'>
                <h2 className='heading'>What are you looking for?</h2>
                <ul className='tab-menu'>
                    <li>
                        <Button onClick={props.isUserAuthenticated?protectTechnology:()=>props.REGISTRATION(true)} className='tab-menu-link'><h4>Protect Your Technology</h4></Button>
                    </li>
                    <li>
                        <Button onClick={props.isUserAuthenticated?addlistTechnology:()=>props.REGISTRATION(true)} className='tab-menu-link'><h4>List Your Technology</h4></Button>
                    </li>
                    <li>
                        <Button onClick={props.isUserAuthenticated?saleTechnology:()=>props.REGISTRATION(true)} className='tab-menu-link'><h4>Tech Seeking!</h4></Button>
                    </li>
                    <li>
                        <Link to="/allcategory" className='tab-menu-link' replace={true}><h4>Tech Hive</h4></Link>
                    </li>
                </ul>
            </section>
            <Container>
                <section className='showcase'>
                    <ShowCase/>
                 </section>
            </Container>
            <ListTechnology isOpen={isOpen} setIsOpen={setIsOpen}/>
            <Protecttechnology isOpen={protect} setIsOpen={setProtect}/>
            <TechnologySale isOpen={isSale} setIsOpen={setIsSale}/>
            {/* <AllCategory isOpen={listAll} setIsOpen={setListAll}/> */}
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Technology);