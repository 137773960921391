import React, { Component, Fragment } from 'react';
import { Collapse, CardBody, Card} from 'reactstrap';
import {forEach} from "lodash"
import './accordion.scss'
// import { ChevronDown, ChevronUp } from '../Icons';

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      collapseTechnologies: "", 
      collapseSubTechnologies: "", 
      collapseTechnologiesList: "", 
      collapseLayout:"",
      data:{},
      rootCategory:{},
      editId : null,
      itemName: undefined,
      deleteItem:null, 
      isModalOpen:false
  
  };
    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    let event = e.target.dataset.event;
    let key = e.target.dataset.type;
    switch(key){
      case "collapseTechnologies":{
        this.setState({ collapseTechnologies: event, collapseSubTechnologies: "", collapseTechnologiesList: "", collapseLayout:""})
          break;
      }
      case "collapseSubTechnologies": {
        this.setState({ collapseSubTechnologies: event, collapseTechnologiesList: "", collapseLayout: "" })
        break;
      }
      case "collapseTechnologiesList": {
        this.setState({ collapseTechnologiesList: event, collapseLayout: "" })
        break;
      }
      case "collapseLayout":{
        this.setState({ collapseLayout: event })
        break;
      }
      default: break;
    }
  }


//   renderLayouts(layouts) {
//     let result = [];
//     forEach(layouts, (layout, index) => {
//       result.push(<Card style={{ marginBottom: '1rem' }} key={layout}>
//         <CardHeader onClick={this.toggle} data-event={layout.layout} data-type="collapseLayout">{layout.layout_name}</CardHeader>
//         <Collapse isOpen={this.state.collapseLayout === layout.layout}>
//           <CardBody>
//             render release form {layout.layout_name}
//           </CardBody>
//         </Collapse>
//       </Card>)
//     })
//     return result;
//   }
  renderSubTechnologies(mainCate, subTechnologies) {
    let result = [];
    forEach(subTechnologies, (subTechnologies, index) => {
      result.push(<Card key={subTechnologies._id}>
        <div onClick={()=>this.props.goToProductList("MAIN_CATEGORY", subTechnologies._id)} data-event={subTechnologies._id} data-type="collapseTechnologiesList" className='sub'>
          {subTechnologies.name}
        </div>
        {/* <Collapse isOpen={this.state.collapseTechnologiesList === subTechnologies._id}>
          <CardBody>
            {this.renderLayouts(release)}
            {subTechnologies.name}
          </CardBody>
        </Collapse> */}
      </Card>)
    })
    return result;
  }
  renderSubCategory(mainCate, subCategory) {
    let result = [];
    forEach(subCategory, (subCategory, index) => {
      result.push(<Card key={subCategory._id} className='subCategoryWrapper'>
        <div onClick={subCategory.technologies.length?this.toggle:()=>this.props.goToProductList("SUB_CATEGORY", subCategory._id)} data-event={subCategory._id} data-type="collapseSubTechnologies" className='subCategory'>
          {subCategory.name} {subCategory.technologies.length > 0 && <span className='noItem'>({subCategory.technologies.length})</span>}
          {/* {this.state.collapseSubTechnologies === subCategory._id?  <ChevronUp color='rgb(255,255,255)' width={18} height={18}/> : <ChevronDown color='rgb(255,255,255)' width={18} height={18}/>} */}
        </div>
        {subCategory.technologies.length ? 
        <Collapse isOpen={this.state.collapseSubTechnologies === subCategory._id}>
          <CardBody>
          {subCategory.technologies.length ? this.renderSubTechnologies(mainCate, subCategory.technologies): null
          }
          </CardBody>
        </Collapse>
        :null}
      </Card>)
    })
    return result;
  }
  renderTechnologies(){
      let result = []
      forEach(this.props.technologiesData,(item, index)=>{
          result.push(
            <Card key={item._id} className="cate_card">
            <div onClick={this.toggle} data-event={item._id} data-type="collapseTechnologies" className='root'>
                {item.name} {item.subCategory.length > 0 && <span className='noItem'>({item.subCategory.length})</span>}
                {/* {this.state.collapseTechnologies === item._id?  <ChevronUp color='#1A1818' width={18} height={18}/> : <ChevronDown color='#1A1818' width={18} height={18}/>} */}
            </div>
            <Collapse isOpen={this.state.collapseTechnologies === item._id}>
              <CardBody>
                {item.subCategory.length ? this.renderSubCategory(item, item.subCategory): 
                null}            
              </CardBody>
            </Collapse>
          </Card>
          )
      })
      return result;
  }

  render() {
    return (
      <Fragment>
          {this.renderTechnologies()}
      </Fragment>
    );
  }
}

export default Accordion;