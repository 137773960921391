import Auth from '../../Models/Auth'
import * as Types from '../../Constents/actionTypes'
import  {} from '../../Models/Auth'
// import PasswordHandling from '../../Models/Auth';


/* Login Functionality */
export const SET_USER_LOGIN=(payload)=> async (dispatch) => {
    try {
        const {username,password} = payload
        if(!username || !password) {
            dispatch({ type: Types.AUTH_LOGIN_ERROR , authLoginError: `Please Provide User Information `});
            return
        }
        const authResponse = await Auth.loginWithUserNameAndPassword(payload)
        let {token} = authResponse.token;
        dispatch({type: Types.SET_TOKEN , token})
        dispatch({type: Types.SET_REFRESH_TOKEN, refreshToken: authResponse.refreshToken })
        dispatch({ type: Types.IS_USER_AUTHENTICATED , isUserAuthenticated: true})
        dispatch({type:Types.USER_TOKEN, userToken: authResponse.userToken})
    } catch(error) {
        return dispatch({ type: Types.AUTH_LOGIN_ERROR , authLoginError: error.message});
    }
}

export const SET_LOGIN_AUTH_ERROR = (message) => (dispatch) => {
    dispatch({ type: Types.AUTH_LOGIN_ERROR , authLoginError: message});
}

export const SET_IS_USER_AUTHENTICATED=(isUserAuthenticated, userToken) => (dispatch)=>{
    dispatch({ type: Types.IS_USER_AUTHENTICATED , isUserAuthenticated})
    dispatch({type:Types.USER_TOKEN, userToken: userToken})
}

/* ----- Forgot Password actions Start ----- */

// export const ADMIN_INIT_FORGOT_PASSWORD = (payload) => async (dispatch)=> {
//     const {username} = payload
//     if(!username) {
//         return dispatch({ type: Types.ADMIN_FORGOT_PASSWORD_ERROR , forgotPswdError:  `Please Provide User Name ` })
//     }
//     try {
//         await PasswordHandling.initiateForgotPassword(payload);
//         // console.log(`init Forgot Password :`, initForgotPWD);
//     }catch(error) {
//         return dispatch({ type: Types.ADMIN_FORGOT_PASSWORD_ERROR , forgotPswdError:  error.message })
//     }
// }

// export const SET_ADMIN_FORGOT_PASSWORD_ERROR = (message) => (dispatch) => {
//      dispatch({ type: Types.ADMIN_FORGOT_PASSWORD_ERROR , forgotPswdError: message })
// }

// export const CONFIRM_FORGOT_PASSWORD = (payload) => async (dispatch) => {
//     try {
//         const {username , password, confirmPassword} = payload
//         if(!username || !password || !confirmPassword) {
//         return dispatch({ type: Types.ADMIN_FORGOT_PASSWORD_ERROR , forgotPswdError: `Please Provide Valid New User Password Information `})
//         }
//         if(password !== confirmPassword) {
//             return dispatch({ type: Types.ADMIN_FORGOT_PASSWORD_ERROR , forgotPswdError: `Passwords mismatch Occurred`})
//         }

//         await PasswordHandling.confirmPasswordResponse(payload)
//     } catch(exception) {
//         return  dispatch({ type: Types.ADMIN_FORGOT_PASSWORD_ERROR , forgotPswdError: exception.message })
//     }
// }


/* ------ Forgot Password actions End ------ */

/* ----- Change Password Actions Start */
// export const ON_CHANGE_NEW_PWD=(payload) => async (dispatch) => {
//     try {
//         const {username, password,newPassword} = payload
//         if(!username || !password || !newPassword) {
//             return dispatch({ type: Types.ADMIN_CHANGE_PASSWORD_ERROR , changePWDError: `Please provide Valid User Password Information` })
//         }
//         await PasswordHandling.onChangeNewPassword(payload)
        
//     } catch(exception) {
//         return dispatch({ type: Types.ADMIN_CHANGE_PASSWORD_ERROR , changePWDError: exception.message })
//     }
// }

// export const SET_CHANGE_PWD_ERROR = (message) => (dispatch) => {
//     return dispatch({ type: Types.ADMIN_CHANGE_PASSWORD_ERROR , changePWDError: message })
// }
export const CLEAR=()=> async(dispatch)=>{
    dispatch({
        type: Types.CLEAR
    })
}

export function LOGOUT() {
    return dispatch => {
        Auth.logout()
        dispatch({ type: Types.ON_LOGOUT });
    }
}