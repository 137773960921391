import React, { Fragment, useState} from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button, Alert } from 'reactstrap';
import { connect } from "react-redux";
import './registration.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import {REGISTRATION, FORGOT_PASSWORD, RESEND_EMAIL} from '../../Store/actions/users'
import {SET_USER_LOGIN, CLEAR} from '../../Store/actions/auth'
import Captcha from './Captcha'
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
const mapStateToProps = (state) => ({
    isOpen: state.users.isOpen,
    authLoginError:state.auth.authLoginError,
    userData:state.users.userData
});
const mapDispatchToProps = (dispatch) => ({
    REGISTRATION:(payload)=> dispatch(REGISTRATION(payload)),
    SET_USER_LOGIN:(payload)=> dispatch(SET_USER_LOGIN(payload)),
    FORGOT_PASSWORD:(payload)=>dispatch(FORGOT_PASSWORD(payload)),
    RESEND_EMAIL:(payload)=>dispatch(RESEND_EMAIL(payload)),
    CLEAR:()=>dispatch(CLEAR())
  });

  
const Registration =(props)=>{
    const [registration, setRegistration] = useState(false)
    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [newUserData, setNewUserData] = useState({}); 
    const [errMsg, setErrMsg] = useState("")
    const [verification, setVerification] = useState(false)
    let navigate = useNavigate();
    const doLogin=()=>{
        const payload = {
            username : username,
            password : password
        }
        props.SET_USER_LOGIN(payload)
    }
    const closeForm =()=>{
        props.REGISTRATION(false)
        setRegistration(false)
        // setVerification(true)
        navigate('/verification')
    }
    
    const setData = e =>{
        const {name, value} = e.target
        if(name === 'username'){
            setUserName(value)
        }
        if(name === 'password'){
            setPassword(value)
        }
    }
    const setFormData = (e) =>{
        const {name, value} = e.target
        const userData = newUserData
        userData[name] = value
        setNewUserData(
            userData
        )
    }
    const onforgotpassword = ()=>{
        props.REGISTRATION(false)
        props.FORGOT_PASSWORD(true)
    }
    const closePopup = ()=>{
        props.REGISTRATION(false)
        setRegistration(false)
        setVerification(false)
    }
    useEffect(()=>{
        if(props.authLoginError){
            setErrMsg("Check Your Email id and Password")
            setTimeout(() => {
                setErrMsg("")
                props.CLEAR()
            }, 5000);
        }
        // eslint-disable-next-line
    }, [props.authLoginError])

    const resendVerification =()=>{
        const payload ={
            email: newUserData.email,
            id: props.userData._id
        }
        props.RESEND_EMAIL(payload)
    }   
    const loginForm = ()=>{
        setRegistration(false)
        setVerification(false)
    }
    return(
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen} 
                // toggle={()=>props.REGISTRATION(false)}
            >
                {registration ?
                
                <Fragment>
                <ModalHeader toggle={closePopup}>
                    Sign up and <br/>get ready for success!
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={{size:8, offset:2}}>
                            <Row>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='email' placeholder='Email*' className='inputField' name="email" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='password' placeholder='Password*' className='inputField' name="password" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='First Name*' className='inputField' name="firstName" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Last Name*' className='inputField' name="lastName" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Phone No' className='inputField' name="phoneNumber" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Organization' className='inputField' name='organization' onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                            </Row>
                            <Captcha newUserData={newUserData} closeForm={closeForm}/>
                        </Col>
                    </Row>
                </ModalBody>
                </Fragment>
                : verification?
                <Fragment>
                    <ModalHeader toggle={closePopup}>
                    Verify your Email
                    </ModalHeader>
                    <ModalBody>
                        <div className='verifyMail'>
                        <div>
                        Thanks for Sign up, We send an email to {newUserData.email}.<br/><br/>

                        You Need to verify your email to continue.<br/>
                        if you have not received the verification email, please check your "Spam" or "Bulk Email" Folder. You can also click the resend button below to have another email send to you.
                        </div>
                        <Button className='cta_btn mt-4' onClick={resendVerification}> <h5>Resend Verification Email</h5></Button>
                        
                        <div className='link_btn ml-3' onClick={loginForm}>Click here for Login</div>
                        </div>
                    </ModalBody>
                    </Fragment>
                :
                <Fragment>
                    <ModalHeader toggle={closePopup}>
                        <div className="signUp_head">Sign in and leave your worries to us! </div>
                    </ModalHeader>
                    <ModalBody>
                    <Row>
                        <Col xs={{size:8, offset:2}}>
                            <Row>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='email' placeholder='Email' className='inputField' name="username" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='password' placeholder='Password' className='inputField'  name="password" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <Button className='cta_btn' onClick={() => doLogin()}> <h5>Login</h5> </Button>

                                    {/* If not a member <span className='link_btn' onClick={()=>setRegistration(!registration)}>Click here</span> to Sign up */}
                                </Col>
                                <Col xs={4}>
                                    <span className='link_btn' onClick={()=>setRegistration(!registration)}>Sign up</span>

                                    {/* <div className='link_btn' onClick={onforgotpassword}>Forget Password</div> */}
                                </Col>
                                {errMsg ?<Col xs={12} className="mt-2">
                                    <Alert color='danger'>{errMsg}</Alert>
                                </Col>:null}
                                <Col xs={12} className="text-center mt-4">
                                <div className='link_btn' onClick={onforgotpassword}>Forget Password</div>
                                {/* <Button className='cta_btn' onClick={() => doLogin()}> <h5>Login</h5> </Button> */}
                                </Col>
                            </Row>
                        
                        </Col>
                    </Row>
                    </ModalBody>
                </Fragment>
                }
                
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Registration);