import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import "./brands.scss";
import rightImg from "../../Assets/input-right.png";
import leftImg from "../../Assets/input-left.png";
import textAreaLeft from "../../Assets/input_1.png";
import textAreaRight from "../../Assets/input_2.png";
import { ADD_NEW_BRAND } from "../../Store/actions/submitForm";
import {toast} from 'react-toastify'

const mapStateToProps = (state) => ({
  addedBrand: state.submitForm.addedBrand,
  userToken:state.auth.userToken
});
const mapDispatchToProps = (dispatch) => ({
  ADD_NEW_BRAND: (payload) => dispatch(ADD_NEW_BRAND(payload)),
});

const ListBrand = (props) => {
  const [newFormData, setNewFormData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false)

    useEffect(()=>{
        if(props.addedBrand){
            setIsSuccess(true)
            setTimeout(() => {
                closeForm()
                setIsSuccess(false)
            }, 3000);
        }
        // eslint-disable-next-line
    }, [props.addedBrand])


  const closeForm = () => {
    props.setIsOpen(false);
  };

  const setFormData = (e) => {
    const { name, value } = e.target;
    const formData = newFormData;
    formData[name] = value;
    setNewFormData(formData);
  };
  const addlistBrandData = () => {
    const {tradeMark, lookingfor, domain, useDetails} = newFormData
    if(!lookingfor || !tradeMark || !domain || !useDetails){
      toast.error("Please fill All Field")
      return
  }else{
    const payload = {
      ...newFormData,
      userId:props.userToken.userId,
      adminName:"Info",
      adminEmail:"info@honeybeezz.co"
    }
    // console.log(payload);
    props.ADD_NEW_BRAND(payload)}
  };
  return (
    <Fragment>
      <Modal
        size="md"
        isOpen={props.isOpen} // toggle={()=>props.REGISTRATION(false)}
      >
        {isSuccess ?
                <Fragment>
                    <div className='isSuccess'>
                    <h2>Thank you</h2>
                    <h4>for sharing your requirements with us.<br/>
                        We will contact you shortly.</h4>
                    </div>
                </Fragment> :<Fragment>
          <ModalHeader toggle={closeForm}>List your Brand</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={{ size: 10, offset: 1 }}>
                <Row>
                  <Col xs={12}>
                    <div className="inputWrapper">
                      <img src={leftImg} alt="" className="inputLeft" />
                      <Input
                        type="text"
                        placeholder="Trademark*"
                        className="inputField"
                        name="tradeMark"
                        onChange={setFormData}
                      />
                      <img src={rightImg} alt="" className="inputRight" />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="inputWrapper">
                      <img src={leftImg} alt="" className="inputLeft" />
                      <Input
                        type="text"
                        placeholder="Domain*"
                        className="inputField"
                        name="domain"
                        onChange={setFormData}
                      />
                      <img src={rightImg} alt="" className="inputRight" />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="inputWrapper">
                      <img src={leftImg} alt="" className="inputLeft" />
                      <Input
                        type="text"
                        placeholder="Use Details(Since)*"
                        className="inputField"
                        name="useDetails"
                        onChange={setFormData}
                      />
                      <img src={rightImg} alt="" className="inputRight" />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="inputWrapper">
                      <img src={leftImg} alt="" className="inputLeft" />
                      <Input
                        type="text"
                        placeholder="Class"
                        className="inputField"
                        name="class"
                        onChange={setFormData}
                      />
                      <img src={rightImg} alt="" className="inputRight" />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="inputWrapper">
                      <img src={leftImg} alt="" className="inputLeft" />
                      <Input
                        type="date"
                        placeholder="Filing date: dd/mm/yyyy"
                        className="inputField"
                        name="fillingDate"
                        onChange={setFormData}
                      />
                      <img src={rightImg} alt="" className="inputRight" />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="inputWrapper">
                      <img src={leftImg} alt="" className="inputLeft" />
                      <Input
                        type="text"
                        placeholder="Status"
                        className="inputField"
                        name="status"
                        onChange={setFormData}
                      />
                      <img src={rightImg} alt="" className="inputRight" />
                    </div>
                  </Col>
                  <Col xs={4} className="txt-white">Interested in*</Col>
                  <Col xs={8}>
                    <FormGroup tag="fieldset">
                      <FormGroup check inline>
                        <Input
                          name="lookingfor"
                          type="radio"
                          onChange={setFormData}
                          value="Brand License"
                        />
                        <Label check className="txt-white">Brand License</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          name="lookingfor"
                          type="radio"
                          onChange={setFormData}
                          value="Sale"
                        />
                        <Label check className="txt-white">Sale</Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <div className="inputWrapper">
                      <img src={textAreaLeft} alt="" className="inputLeft" />
                      <Input
                        type="textarea"
                        placeholder="Terms and Conditions(if any)"
                        className="inputarea"
                        name="tnc"
                        onChange={setFormData}
                      />
                      <img src={textAreaRight} alt="" className="inputRight" />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="inputWrapper">
                      <img src={textAreaLeft} alt="" className="inputLeft" />
                      <Input
                        type="textarea"
                        placeholder="Comments/Message"
                        className="inputarea"
                        name="msg"
                        onChange={setFormData}
                      />
                      <img src={textAreaRight} alt="" className="inputRight" />
                    </div>
                  </Col>
                  <Col xs={12} className="text-center mt-4">
                    <Button className="cta_btn" onClick={addlistBrandData}>
                      <h5>Go</h5>
                    </Button>
                  </Col>
                  <Col xs={12} className="mt-4 mendet">
                      * mark field are mendetory
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
        </Fragment>}
      </Modal>
    </Fragment>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ListBrand);
