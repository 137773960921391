import React, { Fragment} from 'react'
import Slider from "react-slick";



const Carousel =()=>{
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      const items = [
        {
          caption: 'Due diligence by patent experts',
        },
        {
          caption: 'Intellectual property valuation'
        },
        {
          caption: 'Contractual and transaction advisory'
        },
        {
          caption: 'Technology transfer agreements'
        }
      ];  
      const slides = items.map((item, index) => {
        return (
          <h2 key={index} className='headColor' dangerouslySetInnerHTML={{__html:item && item.caption}}></h2>
        );
      });
    return(
        <Fragment>
            <Slider {...settings} className="top-carousel">
                {slides}
            </Slider>
        </Fragment>
    )
}
export default Carousel;