import React, { Fragment, useState } from 'react'
import {Button, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from "react-redux";
import './style.scss'
// import product_img from '../../Assets/product_img_details.png'
import { useNavigate } from "react-router-dom";
import { IMG_URL } from '../../Helpers/constant';
import { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import {REGISTRATION} from '../../Store/actions/users'
import MakeAnOffer from '../ProductActions/makeAnOffer';
import SubmitEnquiry from '../ProductActions/submitEnquiry';
import BuyNow from '../ProductActions/buyNow';

const mapStateToProps = (state) => ({
    productItem:state.Product.productItem,
    isUserAuthenticated: state.auth.isUserAuthenticated,
    brandCategoryList:state.Category.brandCategoryList
});

const mapDispatchToProps = (dispatch) => ({
    REGISTRATION:(payload)=> dispatch(REGISTRATION(payload)),
});
const ProductDetails =(props)=>{
    const [lessContent, setlessContent] = useState(true)
    const [activeTab, setActiveTab] = useState("1")
    const [offerOpen, setOfferOpen] = useState(false)
    const [enquiryOpen, setEnquiryOpen] = useState(false)
    const [buyNowOpen, setBuyNowOpen] = useState(false)
    const [price, setPrice] = useState(undefined)

    let navigate = useNavigate();
    const showmore = ()=>{
        setlessContent(!lessContent)
    }
    const toggleTab = (tabId)=>{
        setActiveTab(tabId)
    }
    // console.log(props.productItem)
    useEffect(()=>{
        if(props.productItem === undefined){
            navigate('/brands')
        }
        // eslint-disable-next-line
    },[props.productItem])
    const {productItem} = props
    
    const onBuyNow = (price) =>{
        setBuyNowOpen(!buyNowOpen)
        setPrice(price)
    }

    const getCategory = (id) =>{
        const {brandCategoryList} = props  
        let data
        if (brandCategoryList) {
        data = brandCategoryList.find(onedata => {
            return (onedata._id && onedata._id === id)
        }
        )
        return data && data.name         
        }
    }

    // debugger
    return(
        <Fragment>
            <Container>
                <div className='product_heading'>{productItem && productItem.productTitle}</div>
                <div className='product_content'>
                    {lessContent? <Fragment><div dangerouslySetInnerHTML={{__html:productItem && productItem.moreDetails && productItem.moreDetails}}></div> <span className='viewmore' onClick={showmore}> view more &#62;&#62;</span></Fragment>:<Fragment><div dangerouslySetInnerHTML={{__html:productItem && productItem.moreDetails && productItem.moreDetails}}></div><span className='viewmore' onClick={showmore}> &#60;&#60; show less </span></Fragment>}
                </div>
                <Row className='align-items-center mb-4'>
                    <Col xl={6}>
                        <img src={`${IMG_URL}${productItem && productItem.imgUrl}`} alt="" className='img-fluid'/>
                    </Col>
                    <Col xl={6}>
                        {props.isUserAuthenticated?productItem && productItem.saleType === "Direct Purchase" && 
                        <Fragment>
                            <p>Sale Price</p>
                            <div className='price_tag'>
                                <h2 className='price'>
                                <CurrencyFormat value={productItem && productItem.salePrice} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} />
                                    /-</h2> 
                                <Button className='cart_btn' onClick={()=>onBuyNow(productItem && productItem.salePrice)}>Buy Now</Button>
                            </div>
                            <p className='mt-3'>Non-Exclusive License Price (Per Year)</p>
                            <div className='price_tag'>
                                <h2 className='price'>
                                <CurrencyFormat value={productItem && productItem.nonExclusiveLicensePrice} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} />
                                /-</h2> 
                                <Button className='cart_btn' onClick={()=>onBuyNow(productItem && productItem.nonExclusiveLicensePrice)}>Get Licence</Button>
                            </div>
                            <p className='mt-3'>Exclusive License Price (Per Year)</p>
                            <div className='price_tag'>
                                <h2 className='price'>
                                <CurrencyFormat value={productItem && productItem.exclusiveLicensePrice} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} />
                                    /-</h2> 
                                <Button className='cart_btn' onClick={()=>onBuyNow(productItem && productItem.exclusiveLicensePrice)}>Get Licence</Button>
                            </div>
                        </Fragment>
                        :<div className='btn_container mt-4'>
                            <Button className='bg_btn' onClick={props.isUserAuthenticated?null:()=>props.REGISTRATION(true)}>Price Listing</Button>
                            <Button className='bg_btn' onClick={props.isUserAuthenticated?null:()=>props.REGISTRATION(true)}>Get Licence</Button>
                        </div>}
                        {/* {productItem && productItem.saleType === "License" &&<div className='btn_container mb-4'> <Button className='bg_btn'>Get Licence</Button></div>} */}
                        <div className='btn_container  mt-4'>
                            <Button className='bg_btn' onClick={props.isUserAuthenticated?setOfferOpen:()=>props.REGISTRATION(true)}>Make an Offer</Button>
                            <Button className='bg_btn' onClick={props.isUserAuthenticated?setEnquiryOpen:()=>props.REGISTRATION(true)}>Submit Enquiry</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
                <div className='mb-4'>
                <Container className='detail_tabs'>
                    <Nav tabs>
                        <NavItem>
                        <NavLink className={activeTab === "1"?"active":""} onClick={() => toggleTab("1")}>
                            Brand Information
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className={activeTab === "4"?"active":""} onClick={() => toggleTab("4")}>
                            More Details
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className={activeTab === "5"?"active":""} onClick={() => toggleTab("5")}>
                            Products Goods
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className={activeTab === "6"?"active":""} onClick={() => toggleTab("6") }>
                            Terms &amp; Conditions
                        </NavLink>
                        </NavItem>
                    </Nav>
                
                    <TabContent activeTab={activeTab} className="detail_tabs_content">
                    <TabPane tabId="1" className='p-4'>
                        <Container>
                            <div className='innerWrapper'>
                                <Row className='rowData'>
                                    <Col xl={3}>Trade Mark:</Col>
                                    <Col><strong>{productItem && productItem.productTitle}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Category:</Col>
                                    <Col><strong>
                                    {getCategory(productItem && productItem.category)}
                                        {/* {productItem && productItem.category} */}
                                        </strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Type:</Col>
                                    <Col><strong>{productItem && productItem.type}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Application Number:</Col>
                                    <Col><strong>{productItem && productItem.appNumber}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Status:</Col>
                                    <Col><strong>{productItem && productItem.status}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Classes:</Col>
                                    <Col><strong>{productItem && productItem.classes}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Filing date:</Col>
                                    <Col><strong>{productItem && productItem.fillingDate}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Registered date:</Col>
                                    <Col><strong>{productItem && productItem.registeredDate}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Owner:</Col>
                                    <Col><strong>{productItem && productItem.patentOwner}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>IP Coverage:</Col>
                                    <Col><strong>{productItem && productItem.IPCoverage}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Opposition details:</Col>
                                    <Col><strong>{productItem && productItem.oppdetails}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Availability:</Col>
                                    <Col><strong>{productItem && productItem.Availability}</strong></Col>
                                </Row>
                            </div>
                        </Container>
                    </TabPane>
                    <TabPane tabId="2" className='p-4'>
                    <Container>
                        <div className='innerWrapper'>
                            <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.moreDetails && productItem.moreDetails}}></div>
                        </div>
                    </Container>
                    </TabPane>
                    <TabPane tabId="3" className='p-4'>
                        <Container>
                            <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.Summary && productItem.Summary}}></div>
                        </Container>
                    </TabPane>
                    <TabPane tabId="4" className='p-4'>
                        <Container>
                            <div className='innerWrapper'>
                                <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.moreDetails && productItem.moreDetails}}></div>
                            </div>
                        </Container>
                    </TabPane>
                    <TabPane tabId="5" className='p-4'>
                        <Container>
                            <div className='innerWrapper'>
                                <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.productsGoods && productItem.productsGoods}}></div>
                            </div>
                        </Container>
                    </TabPane>
                    <TabPane tabId="6" className='p-4'>
                        <Container>
                            <div className='innerWrapper'>
                                <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.tnc && productItem.tnc}}></div>
                            </div>
                        </Container>
                    </TabPane>
                </TabContent>
                </Container>
                </div>
                <MakeAnOffer isOpen={offerOpen} setIsOpen={setOfferOpen}
                    productId={productItem?._id}
                    productType="tech"
                    bidFor={productItem?.productTitle}
                />
                <SubmitEnquiry isOpen={enquiryOpen} setIsOpen={setEnquiryOpen}
                    productId={productItem?._id}
                    productType="tech"
                />
                <BuyNow isOpen={buyNowOpen} setIsOpen={setBuyNowOpen} 
                    saleType={productItem?.saleType}
                    id={productItem?._id}
                    price={price}
                    prductType="Brand"
                    productName={productItem?.productTitle}
                    description={productItem?.patentOwner}
                    image={`${IMG_URL}${productItem && productItem?.imgUrl}`}
                    />
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);