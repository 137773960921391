import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom'
import './Scss/style.scss'

import AppMain from './Layout/AppMain'
import configureStore from './Config/configureStore';
import { Provider } from 'react-redux';

const store = configureStore();

const app = (
  <Router>
      <Provider store={store}>
        <AppMain />
      </Provider>
  </Router>
);

ReactDOM.render(
  app,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
