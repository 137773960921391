import * as Types from '../../Constents/actionTypes';
  
const Product = (state = {}, action) => {
    switch (action.type) {
      
    case Types.GET_TECH_PRODUCT_LIST:
      return {
        ...state,
        techProductList:action.techProductList
      }
    case Types.GET_TECH_PRODUCT_LIST_ERROR:
      return{
        ...state,
        errorMsg: action.error
      }
    case Types.GET_BRAND_PRODUCT_LIST: 
    return {
      ...state,
      brandProductList:action.brandProductList
    }
  case Types.GET_BRAND_PRODUCT_LIST_ERROR:
    return{
      ...state,
      errorMsg: action.error
    }
    case Types.SET_PRODUCT_ID:
      return{
        ...state,
        productItem: action.productItem
      }
    case Types.ALL_TECH_PRODUCT_LIST:
      return{
        ...state,
        allTechproductLits: action.techProductList
      }  
    case Types.ALL_BRAND_PRODUCT_LIST:
      return{
        ...state,
        allBrandproductLits: action.brandProductList
      } 
    case Types.SEARCH_DATA:
      return{
        ...state,
        searchData: action.searchData
      } 
    case Types.BUY_NOW:
      return{
        ...state,
        paymentIsSuccess: action.paymentIsSuccess
      } 
    case Types.MAKE_AN_OFFER:
      return{
        ...state,
        offerSeccess: action.offerSeccess
      } 
    case Types.SEND_ENQUIRY:
      return{
        ...state,
        enquirySeccess: action.enquirySeccess
      }          
    case Types.CLEAR:
      return{
        ...state,
        productItem: undefined,
        enquirySeccess:undefined,
        offerSeccess:undefined
      }          
      default:
        return state;
    }
};
export default Product;