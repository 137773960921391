import * as Types from '../../Constents/actionTypes'
import userModal from '../../Models/user'

export const REGISTRATION = (payload) => (dispatch) => {
    return dispatch({ 
        type: Types.REGISTRATION, 
        isOpen: payload 
    })
}
export const FORGOT_PASSWORD = (payload) => (dispatch) => {
    return dispatch({ 
        type: Types.FORGOT_PASSWORD, 
        isOpen: payload 
    })
}
export const USER_FORGOT_PASSWORD = (payload)=>async (dispatch)=>{
    try {
        const response = await userModal.forgotPassword(payload)
        dispatch({
            type:Types.USER_FORGOT_PASSWORD,
            OtpSend: response
        })
    } catch (error) {
        dispatch({
            type:Types.USER_FORGOT_PASSWORD_ERROR,
            forgotPswdError:error.response.data.MESSAGE
        })
    }
}

export const FORGOT_PASSWORD_ERROR = (payload) => (dispatch) => {
    return dispatch({ 
        type: Types.USER_FORGOT_PASSWORD_ERROR, 
        forgotPswdError: payload 
    })
}

export const RESET_PASSWORD = (payload)=>async (dispatch)=>{
    try {
        const response = await userModal.resetPassword(payload)
        dispatch({
            type:Types.RESET_PASSWORD,
            payload: response
        })
    } catch (error) {
        dispatch({
            type:Types.RESET_PASSWORD_ERROR,
            error
        })
    }
}
export const CHANGE_PASSWORD = (payload)=>async (dispatch)=>{
    try {
        const response = await userModal.changePassword(payload)
        dispatch({
            type:Types.CHANGE_PASSWORD,
            passwordChange: response
        })
    } catch (error) {
        dispatch({
            type:Types.CHANGE_PASSWORD_ERROR,
            error
        })
    }
}

export const RESEND_EMAIL = (payload)=>async (dispatch)=>{
    try {
        const response = await userModal.resendMail(payload)
        dispatch({
            type:Types.RESEND_EMAIL,
            mailResend: response
        })
    } catch (error) {
        dispatch({
            type:Types.RESEND_EMAIL_ERROR,
            error
        })
    }
}
export const CLEAR_RESET = ()=> (dispatch)=>{
    return dispatch({ 
        type: Types.RESET_PASSWORD, 
        payload: "",
        passwordChange:"" 
    })
}
export const CLEAR_MSG = ()=> (dispatch)=>{
    return dispatch({ 
        type: Types.CLEAR, 
    })
}

export const ADD_NEW_USER = (payload) => async (dispatch) =>{
    try {
        const response = await userModal.addNewUser(payload)
        dispatch({
            type: Types.ADD_NEW_USER,
            addedUser : response.msg,
            userData : response.userData
        })
    } catch (exception) {
        dispatch({
            type: Types.ADD_NEW_USER_ERROR,
            exception
        })
    }
}

export const GET_USER_DETAIL = (payload) => async (dispatch)=>{
    try {
        const response = await userModal.getUser(payload)
        dispatch({
            type:Types.GET_USER_DETAIL,
            userDetails: response
        })
    } catch (error) {
        dispatch({
            type:Types.ADD_NEW_USER_ERROR,
            error
        })
    }
}
export const UPDATE_USER_DETAIL = (payload) => async (dispatch)=>{
    try {
        const response = await userModal.updateUser(payload)
        dispatch({
            type:Types.UPDATE_USER_DETAIL,
            updateUserDetails: response
        })
    } catch (error) {
        dispatch({
            type:Types.UPDATE_USER_DETAIL_ERROR,
            error
        })
    }
}

export const GET_STATIC_PAGES = ()=> async (dispatch)=>{
    try {
        const response = await userModal.getPageData()
        dispatch({
            type:Types.PAGE_DATA,
            pageData:response
        })
    } catch (error) {
        
    }
}

export const GET_TEAM = ()=> async (dispatch)=>{
    try {
        const response = await userModal.getTeamData()
        dispatch({
            type:Types.GET_TEAM,
            teamData:response
        })
    } catch (error) {
        
    }
}

export const GET_CLIENT = ()=> async (dispatch)=>{
    try {
        const response = await userModal.getClientData()
        dispatch({
            type:Types.GET_CLIENT,
            clientData:response
        })
    } catch (error) {
        
    }
}

export const GET_ORDER = (payload)=> async (dispatch)=>{
    try {
        const response = await userModal.getOrders(payload)
        dispatch({
            type:Types.GET_ORDERS,
            orderData:response
        })
    } catch (error) {
        
    }
}