import React, { Component } from 'react'; 
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { Row, Col, Input, Button } from 'reactstrap';
import rightImg from '../../Assets/input-right.png';
import leftImg from '../../Assets/input-left.png';
import {toast } from 'react-toastify';
import {ADD_NEW_USER} from '../../Store/actions/users'
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
    
});
const mapDispatchToProps = (dispatch) => ({
    ADD_NEW_USER:(payload) => dispatch(ADD_NEW_USER(payload))
  });
class Captcha extends Component {
    componentDidMount () {
        loadCaptchaEnginge(6,'black','white'); 
    }

    doSubmit = () => {
        let user_captcha = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha)===true) {
            loadCaptchaEnginge(6); 
            document.getElementById('user_captcha_input').value = "";
            this.addNewuser()
        }
        else {
            toast.error('Captha Not Match')
            document.getElementById('user_captcha_input').value = "";
        }
    };
 
    addNewuser =()=>{
        const {newUserData} = this.props
        if(newUserData && newUserData.email && newUserData.password && newUserData.firstName && newUserData.lastName){
            this.props.ADD_NEW_USER(newUserData)
            this.props.closeForm()
        }else{
            toast.error('Please fill all mendetory Field')
        }
        
    }
    render() {
         

        return <Row>
            <Col xs={6}>
                < LoadCanvasTemplate/> 
            </Col>
            <Col xs={6}>
                <div className='inputWrapper'>
                    <img src={leftImg} alt="" className='inputLeft'/>
                    <Input id="user_captcha_input" name="user_captcha_input" type="text" placeholder='Enter Captcha' className='inputField'/>
                    <img src={rightImg} alt="" className='inputRight'/>
                </div>
            </Col>
            <Col xs={12} className="text-center mt-4">
                <Button className='cta_btn' onClick={() => this.doSubmit()}> <h5>GO</h5> </Button>
            </Col>
        </Row>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Captcha);