import axios from 'axios'

class Axios {
    static getInstance() {
        // Initialize instance
        if(!this._axios) {
            const _axios = axios.create({
                headers: {
                    'Content-Type' : 'application/json',
                }
                
            })
            this._axios = _axios
        }
        return this._axios
    }
    
}

export default Axios




// import Auth from '../Models/Auth';
// import axios from 'axios'

// class Axios {
//     static _axios = null
//     static _unauthorizedSubscribers = []
    
//     static getInstance() {
//         // Initialize instance
//         if(!this._axios) {
//             Auth.initialize();
//             const authToken = "Bearer " + Auth.token;
//             const refreshToken = Auth.refreshToken;
//             const userId = Auth.userId;
        
//             const _axios = axios.create({
//                 headers: {
//                     'X-Refresh': refreshToken,
//                     'Authorization': authToken,
//                     'x-userId': userId
//                 }
//             })

//             _axios.interceptors.request.use(function (config){
//                 config.headers['Authorization'] = "Bearer " + Auth.token
//                 return config
//             },error => error)
//             _axios.interceptors.response.use(this._responseInterceptor.bind(this), errorData => {
//                 // console.log(`error response is:`,errorData.response, typeof errorData)
//                 const {
//                     response
//                 } = errorData;


//                 if(response) {
//                     const {
//                         status
//                     } = response;
//                     if(status === 401) {
//                         this.unauthorizedAccess();
//                     }
//                 }
//                 if(response && response.status >= 300) {
//                     if(response.data) { throw response.data }
//                 throw response
//                 }
//                 throw errorData
//             })
//             this._axios = _axios
//         }

//         if(!this._roles) {
//             Auth.initializeRoles()
//             this._roles = Auth.userRolesData
//         }
//         return this._axios
//     }
    
//     static unauthorizedAccess() {
//         // console.log(`un authorized access initiated`)
//         this._unauthorizedSubscribers.forEach(fn => {
//             console.log(`unauthorized access fn`, fn)
//             if(fn instanceof Function) {
//                 fn.call()
//             }
//         })
//         this._unauthorizedSubscribers= []
//     }

//     static subscribeUnauthorizedAccess(fn) {
//         if(!this._unauthorizedSubscribers || !this._unauthorizedSubscribers.length) {
//             this._unauthorizedSubscribers.push(fn)
//         }
//         return fn
//     }

//     static unsubscribeUnauthorizedAccess(fn) {
//         const index = this._unauthorizedSubscribers.findIndex(f => f===fn)
//         if (index !== -1) {
//             this._unauthorizedSubscribers.splice(index,1);
//         }
//     }

//     static resetAxios() {
//         this._axios = null
//     }

//     static _responseInterceptor(response) {
//         if(response) {
//             const { data } = response;
//             const {access, token } = data;
//             if(access || token) {
//                 Auth.setToken({ token , accessToken:access })
//             }
//         }
//         return response
//     }
// }

// export default Axios