import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";
// import LocationMap from './map';
import mapImg from '../../Assets/India-Map-ROYZZ.png'



const mapStateToProps = (state) => ({
    pageData:state.users.pageData
});


const Contact =(props)=>{
    const [defaultData, setDefaultData] = useState('')
    useEffect(()=>{
        if(props.pageData){
            let data = props.pageData.find(oneData=>{
                return oneData.key === 'contact_us'
            })
            setDefaultData(data.value)
        }
    }, [props.pageData])
    return(
        <Fragment>
            <section>
                
            </section>
            <Container>
                <Row className='m-5 pt-5'>
                    <Col xl={7}>
                        <h2>Contact Us</h2>
                        <div dangerouslySetInnerHTML={{__html:defaultData && defaultData}}></div>
                    </Col>
                    <Col xl={5}>
                        {/* <LocationMap/> */}
                        <img src={mapImg} alt='About us' className='img-fluid' />
                    </Col>
                </Row>
                
            </Container>
        </Fragment>
    )
}
export default connect(mapStateToProps)(Contact);