import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { connect } from "react-redux";
// import './technology.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import textAreaLeft from '../../Assets/input_1.png'
import textAreaRight from '../../Assets/input_2.png'
import moment from 'moment'
import { toast } from 'react-toastify'
import { BUY_NOW } from '../../Store/actions/product';

const mapStateToProps = (state) => ({
    userToken: state.auth.userToken,
    paymentIsSuccess: state.Product.paymentIsSuccess
});
const mapDispatchToProps = (dispatch) => ({
    BUY_NOW: (payload) => dispatch(BUY_NOW(payload))
});

const BuyNow = (props) => {

    const [newFormData, setNewFormData] = useState({});
    // const [isSuccess, setIsSuccess] = useState(false)
    const [saleDate] = useState(new Date())
    const { id, price, prductType, productName, description, image, saleType, licenseType } = props

    useEffect(() => {
        if (props.paymentIsSuccess) {
            props.setIsOpen(false)
        }
        // eslint-disable-next-line
    }, [props.paymentIsSuccess])

    const closeForm = () => {
        props.setIsOpen(false)
    }

    const setFormData = (e) => {
        const { name, value } = e.target
        setNewFormData(state => ({
            ...state,
            [name]: value
        }))
    }
    const submitFormData = () => {
        const { address } = newFormData
        if (!address) {
            toast.error('Please fill All Field')
            return
        } else {
            let totalAmount = newFormData.years * price
            const payload = {
                ...newFormData,
                userId: props.userToken.userId,
                productId: id,
                orderDate: moment(saleDate).format('DD-MM-YYYY'),
                amount: totalAmount,
                price: price,
                productType: prductType,
                productName: productName,
                description: description,
                image: image
            }
            //   console.log(payload);
            props.BUY_NOW(payload).then(res => {
                closeForm()
            })
        }
    }

    // console.log(props)

    return (
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen}
            // toggle={()=>props.REGISTRATION(false)}
            >
                {props.paymentIsSuccess ?
                    <Fragment>
                        <div className='isSuccess'>
                            <h2>Thank you</h2>
                            <h4>for sharing your requirements with us.<br />
                                We will contact you shortly.</h4>
                        </div>
                    </Fragment> : <Fragment>
                        <ModalHeader toggle={closeForm}>
                            {licenseType === 'nonExclusiveLicensePrice' ? <span style={{ fontSize: "2.5rem" }}>Buy Non-Exclusive License Now</span> : licenseType === 'exclusiveLicensePrice' ? <span style={{ fontSize: "2.5rem" }}>Buy Exclusive License Now</span> :
                                <span style={{ fontSize: "3.5rem" }}>Buy Now</span>}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xl={{ size: 8, offset: 2 }}>
                                    <Row>
                                        <Col xs={12}>
                                            <Row>
                                                {saleType === "License" &&
                                                    <>
                                                        <Col xs={7}>
                                                            <span style={{ color: "#FFF" }}>Enter No. of Years</span>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <div className='inputWrapper'>
                                                                <img src={leftImg} alt="" className='inputLeft' />
                                                                <Input type='text' required placeholder='Years' className='inputField' name="years" onChange={setFormData} />
                                                                <img src={rightImg} alt="" className='inputRight' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                }

                                                <Col xs={12}>
                                                    <div className='inputWrapper'>
                                                        <img src={textAreaLeft} alt="" className='inputLeft' />
                                                        <Input type='textarea' required placeholder='Billing Address' className='inputarea' name="address" onChange={setFormData} />
                                                        <img src={textAreaRight} alt="" className='inputRight' />
                                                    </div>
                                                </Col>
                                                <Col xs={12} className="text-center">
                                                    <Button className='cta_btn' onClick={submitFormData}> <h5>Pay Now</h5> </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </ModalBody>
                    </Fragment>}
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(BuyNow);