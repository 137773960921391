import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";
import './style.scss'
import { IMG_URL } from '../../Helpers/constant';

const mapStateToProps = (state) => ({
    searchData:state.Product.searchData,
    allBrandproductLits:state.Product.allBrandproductLits,
    allTechproductLits:state.Product.allTechproductLits,
    techCategoryList:state.Category.techCategoryList,
    brandCategoryList:state.Category.brandCategoryList
});



const Search =(props)=>{

    const [defaultData, setDefaultData] = useState('')
    
    useEffect(()=>{
        if(props.searchData){
            const {allBrandproductLits, allTechproductLits} = props
            let allData = allBrandproductLits.concat(allTechproductLits)   
            // console.log(allData)

            const searchResult = allData && allData.length && allData.filter(item => {
                return getCategory(item.category, props.searchData)
              })
            // const searchTechResult = allTechproductLits && allTechproductLits.length && allTechproductLits.filter(item => {
            //     return getTechCategory(item.category, props.searchData)
            //   })
              
            //  let finalResult = searchBrandResult.concat(searchTechResult) 
             
             setDefaultData(searchResult)
        }
        // eslint-disable-next-line
    }, [props.searchData])

    const getCategory = (id, searchData)=>{
        const {brandCategoryList, techCategoryList} = props
        const finalList = techCategoryList.concat(brandCategoryList)
        // console.log(finalList)
        if (finalList){
            let data
            if (finalList) {
            data = finalList.find(onedata => {
                return (onedata._id && onedata._id === id && onedata.name.toLowerCase().includes(searchData.toLowerCase()))
            }
            )
            return data
            }
        }
    }

    // const getTechCategory = (id, searchData)=>{
    //     const {techCategoryList} = props
    //     if (techCategoryList){
    //         let data
    //         if (techCategoryList) {
    //         data = techCategoryList.find(onedata => {
    //             return (onedata._id && onedata._id === id && onedata.name.toLowerCase() === searchData.toLowerCase())
    //         }
    //         )
    //         return data
    //         }
    //     }
    // }

    const getCateName = (category)=>{
        const {techCategoryList, brandCategoryList} = props
        const finalList = techCategoryList.concat(brandCategoryList)
        if (finalList){
            let data
            if (finalList) {
            data = finalList.find(onedata => {
                return (onedata._id && onedata._id === category)
            }
            )
            return data && data.name
            }
        }
    }

    // console.log(defaultData)
    return(
        <Fragment>
                <Container>
                    <Row className='m-5 pt-5 searchWrapper'>
                        <Col xl={{size:8, offset:2}}>
                            <h2>Search Results &lsquo;<span className='searchWord'>{props.searchData}</span>&rsquo;</h2>
                            {defaultData && defaultData.length ? defaultData.map((item,index)=>{
                                return <div className='searchResult'>
                                    <span className='searchWord h4'>{getCateName(item.category)} </span><span  className='h4 cateName'>- Category</span>
                                    <Row className='mt-3'>
                                        <Col xl={3}><img src={`${IMG_URL}${item && item.imgUrl}`} alt="" width="100%"/></Col>
                                        <Col xl={9}>{item.productTitle}
                                        <p dangerouslySetInnerHTML={{__html:item && item.Summary}}></p>
                                        <p dangerouslySetInnerHTML={{__html:item && item.moreDetails}}></p></Col>
                                        
                                    </Row>
                                    
                                    </div>
                            }):
                            <div className='noProduct page_bg'>
                                <div className='contantWrapper'>
                                    <h2>Oops</h2>
                                    <h5>we couldn’t find <strong>{props.searchData}</strong></h5>
                                </div>
                                
                            </div>}
                        </Col>
                    </Row>
                    
                </Container>
        </Fragment>
    )
}
export default connect(mapStateToProps)(Search);