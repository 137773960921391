import React, { Fragment} from 'react'
import { Row, Col } from 'reactstrap';
import clientImg from '../../Assets/client-img.png'
// import Client1 from '../../Assets/client-1.png'
// import Client2 from '../../Assets/client-2.png'
// import Client3 from '../../Assets/client-3.png'
// import Client4 from '../../Assets/client-4.png'
// import Client5 from '../../Assets/client-5.png'
// import Client6 from '../../Assets/client-6.png'
import Slider from "react-slick";
import { IMG_URL } from '../../Helpers/constant';


const Clients =(props)=>{
    const {clientData} = props
    console.log(clientData)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
      };
    const items = clientData && clientData.length && clientData.map(item=>({
        clinet1:`${IMG_URL}${item.imgUrl}`
    }))
    //   const items = [
    //     {
    //         clinet1:`${IMG_URL}`,
    //         clinet2:Client2
    //     },
    //     {
    //         clinet1:Client3,
    //         clinet2:Client4
    //     },
    //     {
    //         clinet1:Client5,
    //         clinet2:Client6
    //     },
    //     {
    //         clinet1:Client1,
    //         clinet2:Client2
    //     },
    //   ];  
      const slides = items && items.map((item, index) => {
        return (
          <div key={index}>
              <img src={item.clinet1} alt="client1" width="80" className='mb-4 d-none d-sm-block'/>
              {/* <img src={item.clinet2} alt="client2" className='img-fluid'/> */}
          </div>
        );
      });
    return(
        <Fragment>
            <Row>
                <Col xl={12}>
                    <div className='text-center'>
                        <h2 className='heading'>Clients</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={4}>
                    <img src={clientImg} alt="Client" className='img-fluid client-img d-none d-sm-block'/>
                </Col>
                <Col xl={8}>
                    <Slider {...settings} className="client-carousel">
                        {slides}
                    </Slider>  
                </Col>
            </Row>
        </Fragment>
    )
}
export default Clients;