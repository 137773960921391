import Axios from "./Axios";
import {  API_URL, API_REQUESTS } from '../Helpers/constant'

class SubmitForm extends Axios {
    
    static async addListTechnology(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.USERS}${API_REQUESTS.TECHNOLOGY_PUBLISH}`
        return new Promise((resolve,reject)=>{
            // debugger
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async protectTechnology(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.USERS}${API_REQUESTS.TECHNOLOGY_PROTECT}`
        return new Promise((resolve,reject)=>{
            // debugger
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async technologySale(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.USERS}${API_REQUESTS.TECHNOLOGY_SALE}`
        return new Promise((resolve,reject)=>{
            // debugger
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async addBrand(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.USERS}${API_REQUESTS.BRAND_PUBLISH}`
        return new Promise((resolve,reject)=>{
            // debugger
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async protectBrand(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.USERS}${API_REQUESTS.BRAND_PROTECT}`
        return new Promise((resolve,reject)=>{
            // debugger
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async brandSale(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.USERS}${API_REQUESTS.BRAND_SALE}`
        return new Promise((resolve,reject)=>{
            // debugger
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

}
export default SubmitForm;