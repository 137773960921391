import React, { Fragment, useState} from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { connect } from "react-redux";
// import './registration.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import {UPDATE_USER_DETAIL, CLEAR_RESET} from '../../Store/actions/users'

import { useEffect } from 'react';
// import Captcha from './Captcha'

const mapStateToProps = (state) => ({
    updateUserDetails:state.users.updateUserDetails
});
const mapDispatchToProps = (dispatch) => ({
    UPDATE_USER_DETAIL:(payload)=> dispatch(UPDATE_USER_DETAIL(payload)),
    CLEAR_RESET:()=> dispatch(CLEAR_RESET())
  });

  
const EditProfile =(props)=>{
    
    // const [errMessage, setErrMessage] = useState("")
    const [successMsg, setSuccessMsg] = useState("")
    const [profileData, setNewProfileData] = useState('')

    

    useEffect(()=>{
        if(props.updateUserDetails) {
            setSuccessMsg(props.updateUserDetails)
            props.closeForm()
         }
          // eslint-disable-next-line
    },[props.updateUserDetails])
    

    
    // const setData = e =>{
    //     const {name, value} = e.target
    //     if(name === 'oldPassword'){
    //         setOldPassword(value)
    //     }
    //     if(name === 'newPassword'){
    //         setNewPassword(value)
    //     }
    //     if(name === 'confirmPassword'){
    //         setConfirmPassword(value)
    //     }
    // }
    
    const setData = e =>{
        const {name, value} = e.target
        setNewProfileData(stats=>({
            ...stats,
            [name]:value
        }))
    }
    
    const doUpdateProfile =()=>{
          props.UPDATE_USER_DETAIL(profileData)
    }
    return(
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen} 
            >
                <Fragment>
                <ModalHeader toggle={props.closeForm}>
                    Update User Profile
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={{size:8, offset:2}}>
                            <Row>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='email' className='inputField' name="email" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='First Name' className='inputField' name="firstName" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Last Name' className='inputField' name="lastName" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Phone No' className='inputField' name="phoneNumber" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12} className="text-center mt-4">
                                    <Button className='cta_btn' onClick={() => doUpdateProfile()}> <h5>Submit</h5> </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* { (errMessage) ? (
                            <div className="mt-4 alert alert-danger text-center">{errMessage} * </div>
                        ): null
                        } */}
                        { (successMsg) ? (
                            <div className="mt-4 alert alert-success text-center">{successMsg} * </div>
                        ): null
                        }
                </ModalBody>
                </Fragment>
                
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);