import React, { Fragment, useState, useEffect} from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { connect } from "react-redux";
import './technology.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import textAreaLeft from '../../Assets/input_1.png'
import textAreaRight from '../../Assets/input_2.png'
import {ADD_NEW_TECHNOLOGY} from '../../Store/actions/submitForm'
import {toast} from 'react-toastify'

const mapStateToProps = (state) => ({
    addedtechnology:state.submitForm.addedtechnology,
    userToken:state.auth.userToken
});
const mapDispatchToProps = (dispatch) => ({
    ADD_NEW_TECHNOLOGY:(payload)=> dispatch(ADD_NEW_TECHNOLOGY(payload))
});

  
const ListTechnology =(props)=>{
    
    const [newFormData, setNewFormData] = useState({}); 
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(()=>{
        if(props.addedtechnology){
            setIsSuccess(true)
            setTimeout(() => {
                closeForm()
                setIsSuccess(false)
            }, 3000);
        }
        // eslint-disable-next-line
    }, [props.addedtechnology])


    const closeForm =()=>{
        props.setIsOpen(false)
    }
    
    const setFormData = (e) =>{
        const {name, value} = e.target
        const formData = newFormData
        formData[name] = value
        setNewFormData(
            formData
        )
    }
    const addlistTechnologyData = ()=>{
        const {appNumber, title, domain, country, status} = newFormData

        if(!appNumber ||  !title|| !domain ||!country || !status){
            toast.error("Please fill All Field")
            return
        }else{
        const payload = {
            ...newFormData,
            userId:props.userToken.userId,
            adminName:"Info",
            adminEmail:"info@honeybeezz.co"
        }

        // console.log(payload)
        props.ADD_NEW_TECHNOLOGY(payload)
        }
    }
    return(
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen} 
                // toggle={()=>props.REGISTRATION(false)}
            >
                {isSuccess ?
                <Fragment>
                    <div className='isSuccess'>
                    <h2>Thank you</h2>
                    <h4>for sharing your requirements with us.<br/>
                        We will contact you shortly.</h4>
                    </div>
                </Fragment> :<Fragment>
                <ModalHeader toggle={closeForm}>
                    List your Technology
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={{size:10, offset:1}}>
                            <Row>
                                <Col xs={6}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Application Number*' className='inputField' name="appNumber" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Title*' className='inputField' name="title" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Domain*' className='inputField' name="domain" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Country*' className='inputField' name="country" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Status*' className='inputField' name="status" onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={textAreaLeft} alt="" className='inputLeft'/>
                                        <Input type='textarea' placeholder='Product Details(if any)' className='inputarea' name="productDetails" onChange={setFormData}/>
                                        <img src={textAreaRight} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Market Demand' className='inputField' name='marketDemand' onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='Interested In' className='inputField' name='interestedIn' onChange={setFormData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={textAreaLeft} alt="" className='inputLeft'/>
                                        <Input type='textarea' placeholder='Terms and Conditions(if any)' className='inputarea' name="tnc" onChange={setFormData}/>
                                        <img src={textAreaRight} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={textAreaLeft} alt="" className='inputLeft'/>
                                        <Input type='textarea' placeholder='Comments/Message' className='inputarea' name="msg" onChange={setFormData}/>
                                        <img src={textAreaRight} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12} className="text-center mt-4">
                                <Button className='cta_btn' onClick={addlistTechnologyData}> <h5>Go</h5> </Button>
                                </Col>
                                <Col xs={12} className="mt-4 mendet">
                                        * mark field are mendetory
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                </Fragment>}
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ListTechnology);