import React, { Fragment, useState } from 'react'
import { Button } from 'reactstrap';
import {Link} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './brands.scss'
import ListBrand from './listBrand'
import ProtectBrand from './protectBrand'
import { connect } from "react-redux";
import BrandSale from './brandSale';
import {REGISTRATION} from '../../Store/actions/users'

const mapStateToProps = (state) => ({
    isUserAuthenticated: state.auth.isUserAuthenticated
});
const mapDispatchToProps = (dispatch) => ({
    REGISTRATION:(payload)=> dispatch(REGISTRATION(payload)),
});
const Brands =(props)=>{
    const [isOpen, setIsOpen] = useState(false)
    const [protect, setProtect] = useState(false)
    const [isSale, setIsSale] = useState(false)

    const addBrand = ()=>{
        setIsOpen(!isOpen)
    }
    const protectBrand = ()=>{
        setProtect(!protect)
    }
    const saleBrand = ()=>{
        setIsSale(!isSale)
    }
    return(
        <Fragment>
        <section className='tech-banner'>
        </section>
        <section className='content-wrapper'>
            <h2 className='heading'>What are you looking for?</h2>
            <ul className='tab-menu'>
                <li>
                    <Button onClick={props.isUserAuthenticated?protectBrand:()=>props.REGISTRATION(true)} className='tab-menu-link'><h4>Protect Your Brand</h4></Button>
                </li>
                <li>
                    <Button onClick={props.isUserAuthenticated?addBrand:()=>props.REGISTRATION(true)} className='tab-menu-link'><h4>List Your Brand</h4></Button>
                </li>
                <li>
                    <Button onClick={props.isUserAuthenticated?saleBrand:()=>props.REGISTRATION(true)} className='tab-menu-link'><h4>Brand Seeking!</h4></Button>
                </li>
                <li>
                    <Link to="/allbrands" className='tab-menu-link'><h4>Brand Hive</h4></Link>
                </li>
            </ul>
        </section>
        <ListBrand isOpen={isOpen} setIsOpen={setIsOpen}/>
        <ProtectBrand isOpen={protect} setIsOpen={setProtect}/>
        <BrandSale isOpen={isSale} setIsOpen={setIsSale}/>
    </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Brands);