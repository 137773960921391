export const LOGIN = 'LOGIN'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR'
export const CLEAR = 'CLEAR'
export const SEARCH_DATA = 'SEARCH_DATA'

export const IS_USER_AUTHENTICATED = 'IS_USER_AUTHENTICATED'
export const ON_LOGOUT= 'ON_LOGOUT'
export const USER_TOKEN = 'USER_TOKEN'

export const REGISTRATION = 'REGISTRATION'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'
export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD'
export const USER_FORGOT_PASSWORD_ERROR = 'USER_FORGOT_PASSWORD_ERROR'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const RESEND_EMAIL = 'RESEND_EMAIL'
export const RESEND_EMAIL_ERROR = 'RESEND_EMAIL_ERROR'


export const ADD_NEW_USER = 'ADD_NEW_USER'
export const ADD_NEW_USER_ERROR = 'ADD_NEW_USER_ERROR'
export const GET_USER_DETAIL = 'GET_USER_DETAIL'
export const GET_USER_DETAIL_ERROR = 'GET_USER_DETAIL_ERROR'
export const UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL'
export const UPDATE_USER_DETAIL_ERROR = 'UPDATE_USER_DETAIL_ERROR'

export const ADD_NEW_TECHNOLOGY = 'ADD_NEW_TECHNOLOGY'
export const ADD_NEW_TECHNOLOGY_ERROR = 'ADD_NEW_TECHNOLOGY_ERROR'
export const PROTECT_TECHNOLOGY = 'PROTECT_TECHNOLOGY'
export const PROTECT_TECHNOLOGY_ERROR = 'PROTECT_TECHNOLOGY_ERROR'
export const TECHNOLOGY_SALE = 'TECHNOLOGY_SALE'
export const TECHNOLOGY_SALE_ERROR = 'TECHNOLOGY_SALE_ERROR'

export const ADD_NEW_BRAND = 'ADD_NEW_BRAND'
export const ADD_NEW_BRAND_ERROR = 'ADD_NEW_BRAND_ERROR'
export const PROTECT_BRAND = 'PROTECT_BRAND'
export const PROTECT_BRAND_ERROR = 'PROTECT_BRAND_ERROR'
export const BRAND_SALE = 'BRAND_SALE'
export const BRAND_SALE_ERROR = 'BRAND_SALE_ERROR'

export const GET_TECH_PRODUCT_LIST = 'GET_TECH_PRODUCT_LIST'
export const GET_TECH_PRODUCT_LIST_ERROR = 'GET_TECH_PRODUCT_LIST_ERROR'
export const GET_BRAND_PRODUCT_LIST = 'GET_BRAND_PRODUCT_LIST'
export const GET_BRAND_PRODUCT_LIST_ERROR = 'GET_BRAND_PRODUCT_LIST_ERROR'
export const SET_PRODUCT_ID = 'SET_PRODUCT_ID'

export const GET_TECH_CATEGORY_LIST = 'GET_TECH_CATEGORY_LIST'
export const GET_TECH_CATEGORY_LIST_ERROR = 'GET_TECH_CATEGORY_LIST_ERROR'
export const GET_BRAND_CATEGORY_LIST = 'GET_BRAND_CATEGORY_LIST'
export const GET_BRAND_CATEGORY_LIST_ERROR = 'GET_BRAND_CATEGORY_LIST_ERROR'

export const PAGE_DATA = 'PAGE_DATA'
export const GET_TEAM = 'GET_TEAM'
export const GET_CLIENT = 'GET_CLIENT'
export const GET_ORDERS = 'GET_ORDERS'
export const ALL_TECH_PRODUCT_LIST = 'ALL_TECH_PRODUCT_LIST'
export const ALL_BRAND_PRODUCT_LIST = 'ALL_BRAND_PRODUCT_LIST'
export const ALL_TECH_PRODUCT_LIST_ERROR = 'ALL_TECH_PRODUCT_LIST_ERROR'
export const ALL_BRAND_PRODUCT_LIST_ERROR = 'ALL_BRAND_PRODUCT_LIST_ERROR'


export const BUY_NOW = 'BUY_NOW'
export const BUY_NOW_ERROR = 'BUY_NOW_ERROR'
export const MAKE_AN_OFFER = 'MAKE_AN_OFFER'
export const MAKE_AN_OFFER_ERROR = 'MAKE_AN_OFFER_ERROR'
export const SEND_ENQUIRY = 'SEND_ENQUIRY'
export const SEND_ENQUIRY_ERROR = 'SEND_ENQUIRY_ERROR'