import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { connect } from "react-redux";
// import './technology.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import textAreaLeft from '../../Assets/input_1.png'
import textAreaRight from '../../Assets/input_2.png'
import {toast} from 'react-toastify'
import {SEND_ENQUIRY} from '../../Store/actions/product'
import { GET_USER_DETAIL } from '../../Store/actions/users';
import { useNavigate } from 'react-router';
const mapStateToProps = (state) => ({
    userToken:state.auth.userToken,
    userDetails:state.users.userDetails
});
const mapDispatchToProps = (dispatch) => ({
    SEND_ENQUIRY: (payload) => dispatch(SEND_ENQUIRY(payload)),
    GET_USER_DETAIL:(userId)=> dispatch(GET_USER_DETAIL(userId)),
});


const SubmitEnquiry = (props) => {
    const navigate = useNavigate()
    const [newFormData, setNewFormData] = useState({});
    const [isSuccess, setIsSuccess] = useState(false)
    const {productId, productType} = props

    useEffect(()=>{
        if(props.userToken){
            props.GET_USER_DETAIL(props.userToken.userId)
        }
        // eslint-disable-next-line
    },[props.userToken])

    useEffect(()=>{
        const {userDetails} = props
        if(userDetails){
            setNewFormData(state=>({
                ...state,
                "name": userDetails && userDetails.firstName + " " + userDetails.lastName,
                "email": userDetails && userDetails.email
            }))
        } 
        // eslint-disable-next-line
    },[props.userDetails])


    const closeForm = () => {
        props.setIsOpen(false)
    }

    const setFormData = (e) => {
        const { name, value } = e.target
        setNewFormData(state=>({
            ...state,
            [name]:value
        }))
    }
    const submitFormData = () => {
        const {name, email, subject, type, message} = newFormData
        if(!name || !email || !subject || !type || !message){
            toast.error('Please fill All Field')
            return
        }else{
        // const payload = {
        //     ...newFormData,
        //     userId:props.userToken.userId,
        //     adminName:"Info",
        //     adminEmail:"info@honeybeezz.co"
        //   }
          const payload = {
            ...newFormData,
            "productId":productId,
            "productType":productType,
            "userId":props.userToken.userId,
        }
        //   console.log(payload);
          props.SEND_ENQUIRY(payload).then(res=>{
            setIsSuccess(true)
            setTimeout(() => {
                setIsSuccess(false)
                navigate(-1)
            }, 3000);
          })
        //   setIsSuccess(true)
        }
    }

    

    return (
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen}
            // toggle={()=>props.REGISTRATION(false)}
            >
                {isSuccess ?
                <Fragment>
                    <div className='isSuccess'>
                    <h2>Thank you</h2>
                    <h4>for sharing your requirements with us.<br/>
                        We will contact you shortly.</h4>
                    </div>
                </Fragment> :<Fragment>
                    <ModalHeader toggle={closeForm}>
                        Submit Inquiry
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12}>
                                            <div className='inputWrapper'>
                                                <img src={leftImg} alt="" className='inputLeft'/>
                                                <Input type='text' placeholder='Your Name*' className='inputField' name="name" onChange={setFormData} value={newFormData && newFormData.name} readOnly/>
                                                <img src={rightImg} alt="" className='inputRight'/>
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <div className='inputWrapper'>
                                                <img src={leftImg} alt="" className='inputLeft'/>
                                                <Input type='text' placeholder='Email Id*' className='inputField' name="email" value={newFormData && newFormData.email} onChange={setFormData} readOnly/>
                                                <img src={rightImg} alt="" className='inputRight'/>
                                            </div>
                                        </Col>  
                                        <Col xs={12}>
                                            <div className='inputWrapper'>
                                                <img src={leftImg} alt="" className='inputLeft'/>
                                                <Input type='text' placeholder='Subject*' className='inputField' name="subject" onChange={setFormData}/>
                                                <img src={rightImg} alt="" className='inputRight'/>
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <div className='inputWrapper'>
                                                <img src={leftImg} alt="" className='inputLeft'/>
                                                <Input name="type" type="select"  className='inputField' onChange={setFormData}>
                                                <option> Kind of Enquiry* </option>
                                                <option value="sales"> Sales </option>
                                                <option value="license"> License </option>
                                                <option value="others"> Other </option>
                                                </Input>
                                                <img src={rightImg} alt="" className='inputRight'/>
                                            </div>
                                        </Col>  
                                        <Col xs={12}>
                                            <div className='inputWrapper'>
                                                <img src={textAreaLeft} alt="" className='inputLeft'/>
                                                <Input type='textarea' placeholder='Your Message*' className='inputarea' name="message" onChange={setFormData}/>
                                                <img src={textAreaRight} alt="" className='inputRight'/>
                                            </div>
                                        </Col>
                                    <Col xs={12} className="text-center">
                                        <Button className='cta_btn' onClick={submitFormData}> <h5>Go</h5> </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                </Fragment>}
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(SubmitEnquiry);