import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";
// import teamMemberImg from '../../Assets/profile.png'
import {GET_TEAM} from '../../Store/actions/users'
import { IMG_URL } from '../../Helpers/constant';


import './style.scss'
const mapStateToProps = (state) => ({
    pageData:state.users.pageData,
    teamData:state.users.teamData
});

const mapDispatchToProps = (dispatch) => ({
    GET_TEAM:()=> dispatch(GET_TEAM()),
});

// const teamData = [{
//     userImg:teamMemberImg,
//     memberName:"Mahua Roy",
//     designation:"CEO",
//     description:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna"
// },{
//     userImg:teamMemberImg,
//     memberName:"Muthu",
//     designation:"CEO",
//     description:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna"
// },{
//     userImg:teamMemberImg,
//     memberName:"Deepti Aeron",
//     designation:"Project Manager",
//     description:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna"
// },{
//     userImg:teamMemberImg,
//     memberName:"Nirmallya Roy Chowdhury",
//     designation:"CEO",
//     description:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna"
// },{
//     userImg:teamMemberImg,
//     memberName:"Sharmila Talpady",
//     designation:"Sr. Exe",
//     description:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna"
// }, {
//     userImg:teamMemberImg,
//     memberName:"Rahul Katiyar",
//     designation:"Team Lead",
//     description:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna"
// }]
const About =(props)=>{
    const [defaultData, setDefaultData] = useState('')
    
    useEffect(()=>{
        props.GET_TEAM()
         // eslint-disable-next-line
    },[])
    
    useEffect(()=>{
        if(props.pageData){
            let data = props.pageData.find(oneData=>{
                return oneData.key === 'about_us'
            })
            setDefaultData(data.value)
        }
    }, [props.pageData])

    const {teamData} = props
    console.log(teamData)
    return(
        <Fragment>
            <Container className='about'>
                <Row className='m-5 pt-5'>
                    <Col md={4} xl={5}>
                        <h2 className='about-head'>About us?</h2>
                    </Col>
                    <Col md={8} xl={7}>
                        <div className='about_txt' dangerouslySetInnerHTML={{__html:defaultData && defaultData}}></div>
                    </Col>
                </Row>
                <Row className='teamWrapper align-items-center'>
                    <Col md={5} xl={5} className="d-block d-sm-none">
                        <h2 className='team-head'>Team <br/> HoneyBeezz</h2>
                    </Col>

                    <Col md={7} xl={7}>
                        <div className='team-text'>
                            The Honeybeezz team is a tech-savvy team of lawyers, scientists and engineers who understand the nuances of a scientific invention and engineering. We bring a narration to your innovation in a manner that accurately communicates the invention, novelty and its business implementation.  Each innovation on Honeybeez is verified and assessed to give you the confidence to explore and invest.
                        </div>
                    </Col>
                    <Col md={5} xl={5} className="d-none d-sm-block">
                        <h2 className='team-head'>Team <br/> HoneyBeezz</h2>
                    </Col>
                </Row>
                <Row className='teamMember'>
                   {teamData && teamData.map((item, index)=>{
                    return <Col md={4} xl={4} key={index}>
                        <div className='member'>
                            <img src={`${IMG_URL}${item.imgUrl}`} alt={item.name}/>
                            <h3>{item.name}</h3>
                            <h5>{item.position?item.position:"TBD"}</h5>
                            <p>{item.aboutMe}</p>
                        </div>
                    </Col>
                   })} 
                </Row>
                <Row className='holistic_sction'>
                   <Col md={7} xl={7}>
                    <h2 className='holistic_head'>
                        We provide a
                        holistic view of all
                        intellectual properties
                        housed in one window
                        </h2>
                   </Col>
                   <Col md={5} xl={5}>
                   <ul className="aboutUs_section">
                            <li>
                            <div className="heading">
                                <h6>
                                Due diligence by<br/>
                                patent experts
                                </h6>
                            </div>
                            <div className="hexagon"></div>
                            <div className="number">1</div>
                            
                            </li>
                            <li>
                            <div className="heading">
                                <h6>
                                Intellectual property<br/>
                                valuation
                                </h6>
                            </div>
                            <div className="hexagon"></div>
                            <div className="number">2</div>
                            
                            </li>
                            <li>
                            <div className="heading">
                                <h6>
                                Contractual and<br/>
                                transaction advisory
                                </h6>
                            </div>
                            <div className="hexagon"></div>
                            <div className="number">3</div>
                            
                            </li>
                            <li>
                            <div className="heading">
                                <h6>
                                Technology transfer<br/>
                                agreements
                                </h6>
                            </div>
                            <div className="hexagon"></div>
                            <div className="number">4</div>
                            
                            </li>
                        </ul>
                   </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(About);