import React, { Fragment, useState } from 'react'
import {Button, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from "react-redux";
import './style.scss'
// import product_img from '../../Assets/product_img_details.png'
import { useNavigate } from "react-router-dom";
import { IMG_URL } from '../../Helpers/constant';
import { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import {REGISTRATION} from '../../Store/actions/users'
import {CLEAR} from '../../Store/actions/product'
import MakeAnOffer from '../ProductActions/makeAnOffer';
import SubmitEnquiry from '../ProductActions/submitEnquiry';
import BuyNow from '../ProductActions/buyNow';
const mapStateToProps = (state) => ({
    productItem:state.Product.productItem,
    isUserAuthenticated: state.auth.isUserAuthenticated,
    offerSeccess:state.Product.offerSeccess,
    enquirySeccess:state.Product.enquirySeccess
});
const mapDispatchToProps = (dispatch) => ({
    REGISTRATION:(payload)=> dispatch(REGISTRATION(payload)),
    CLEAR:()=> dispatch(CLEAR())
});
const ProductDetails =(props)=>{
    const [lessContent, setlessContent] = useState(true)
    const [activeTab, setActiveTab] = useState("1")
    const [offerOpen, setOfferOpen] = useState(false)
    const [enquiryOpen, setEnquiryOpen] = useState(false)
    const [buyNowOpen, setBuyNowOpen] = useState(false)
    const [price, setPrice] = useState(undefined)
    const [licenseType, setLicenseType] = useState('')
    let navigate = useNavigate();
    const showmore = ()=>{
        setlessContent(!lessContent)
    }
    const toggleTab = (tabId)=>{
        setActiveTab(tabId)
    }
    
    useEffect(()=>{
        if(props.offerSeccess){
            setOfferOpen(false)
            props.CLEAR();
        }
        if(props.enquirySeccess){
            setEnquiryOpen(false)
            props.CLEAR();
        }
        // eslint-disable-next-line
    },[props.offerSeccess, props.enquirySeccess])

    useEffect(()=>{
        if(props.productItem === undefined){
            navigate('/technology')
        }
        // eslint-disable-next-line
    },[props.productItem])


    const onBuyNow = (price, licenseType) =>{
        setBuyNowOpen(!buyNowOpen)
        setPrice(price)
        setLicenseType(licenseType)
    }

    const {productItem, isUserAuthenticated} = props

    

    // console.log(productItem)
    // debugger
    return(
        <Fragment>
            <Container>
                <div className='product_heading'>{productItem && productItem.productTitle}</div>
                <div className='product_content'>
                    {lessContent? <Fragment><div dangerouslySetInnerHTML={{__html:productItem && productItem.Summary && productItem.Summary}}></div> <span className='viewmore' onClick={showmore}> view more &#62;&#62;</span></Fragment>:<Fragment><div dangerouslySetInnerHTML={{__html:productItem && productItem.Summary && productItem.Summary}}></div><span className='viewmore' onClick={showmore}> &#60;&#60; show less </span></Fragment>}
                </div>
                <Row className='align-items-center mb-4'>
                    <Col xl={6}>
                        <img src={`${IMG_URL}${productItem && productItem.imgUrl}`} alt="" className='img-fluid'/>
                    </Col>
                    <Col xl={6}>
                    {isUserAuthenticated? productItem && productItem.saleType === "Direct Purchase" && 
                        <Fragment>
                            <p>Sale Price</p>
                            <div className='price_tag'>
                                <h2 className='price'>
                                <CurrencyFormat value={productItem && productItem.salePrice} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} />
                                    /-</h2> 
                                <Button className='cart_btn' onClick={()=>onBuyNow(productItem && productItem.salePrice, 'DirectPurchase')}>Buy Now</Button>
                            </div>
                            <p className='mt-3'>Non-Exclusive License Price (Per Year)</p>
                            <div className='price_tag'>
                                <h2 className='price'>
                                <CurrencyFormat value={productItem && productItem.nonExclusiveLicensePrice} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} />
                                /-</h2> 
                                <Button className='cart_btn' onClick={()=>onBuyNow(productItem && productItem.nonExclusiveLicensePrice, 'nonExclusiveLicensePrice')}>Get Licence</Button>
                            </div>
                            <p className='mt-3'>Exclusive License Price (Per Year)</p>
                            <div className='price_tag'>
                                <h2 className='price'>
                                <CurrencyFormat value={productItem && productItem.exclusiveLicensePrice} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} />
                                    /-</h2> 
                                <Button className='cart_btn' onClick={()=>onBuyNow(productItem && productItem.exclusiveLicensePrice, 'exclusiveLicensePrice')}>Get Licence</Button>
                            </div>
                        </Fragment>
                        :<div className='btn_container mt-4'>
                            <Button className='bg_btn' onClick={props.isUserAuthenticated?null:()=>props.REGISTRATION(true)}>Price Listing</Button>
                            <Button className='bg_btn' onClick={props.isUserAuthenticated?null:()=>props.REGISTRATION(true)}>Get Licence</Button>
                        </div>}
                        {/* {productItem && productItem.saleType === "License" &&
                        <div className='btn_container mb-4'> <Button className='bg_btn' onClick={props.isUserAuthenticated?null:()=>props.REGISTRATION(true)}>Get Licence</Button></div>} */}
                        <div className='btn_container  mt-4'>
                            <Button className='bg_btn' onClick={props.isUserAuthenticated?setOfferOpen:()=>props.REGISTRATION(true)}>Make an Offer</Button>
                            <Button className='bg_btn' onClick={props.isUserAuthenticated?setEnquiryOpen:()=>props.REGISTRATION(true)}>Submit Enquiry</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
                <div className='mb-4'>
                <Container className='detail_tabs'>
                    <Nav tabs>
                        <NavItem>
                        <NavLink className={activeTab === "1"?"active":""} onClick={() => toggleTab("1") }>
                            Technology Information
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className={activeTab === "2"?"active":""} onClick={() => toggleTab("2") }>
                            Market Demand
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className={activeTab === "3"?"active":""} onClick={() => toggleTab("3") }>
                            Summary
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className={activeTab === "4"?"active":""} onClick={() => toggleTab("4") }>
                            More Details
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className={activeTab === "5"?"active":""} onClick={() => toggleTab("5") }>
                            Terms &amp; Conditions
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className={activeTab === "6"?"active":""} onClick={() => toggleTab("6") }>
                            Applications
                        </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="detail_tabs_content">
                    <TabPane tabId="1" className='p-4'>
                        <Container>
                            <div className='innerWrapper'>
                                <Row className='rowData'>
                                    <Col xl={3}>Status:</Col>
                                    <Col><strong>{productItem && productItem.status}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Application Number:</Col>
                                    <Col><strong>{productItem && productItem.appNumber}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Grant Number:</Col>
                                    <Col><strong>{productItem && productItem.grantNumber}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Filing date:</Col>
                                    <Col><strong>{productItem && productItem.fillingDate}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Priority date:</Col>
                                    <Col><strong>{productItem && productItem.priorityDate}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Grant date:</Col>
                                    <Col><strong>{productItem && productItem.grantDate}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Patent Owner:</Col>
                                    <Col><strong>{productItem && productItem.patentOwner}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Inventors:</Col>
                                    <Col><strong>{productItem && productItem.Inventors}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>IP Coverage:</Col>
                                    <Col><strong>{productItem && productItem.IPCoverage}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Patent Family:</Col>
                                    <Col><strong>{productItem && productItem.patentFamily}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Current Patent Owner:</Col>
                                    <Col><strong>{productItem && productItem.currentPtentOwner}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Category:</Col>
                                    <Col><strong>{productItem && productItem.category}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Sub Category:</Col>
                                    <Col><strong>{productItem && productItem.subCategory}</strong></Col>
                                </Row>
                                <Row className='rowData'>
                                    <Col xl={3}>Availability:</Col>
                                    <Col><strong>{productItem && productItem.Availability}</strong></Col>
                                </Row>
                            </div>
                        </Container>
                    </TabPane>
                    <TabPane tabId="2" className='p-4'>
                    <Container>
                        <div className='innerWrapper'>
                            <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.MarketDemand && productItem.MarketDemand}}></div>
                        </div>
                    </Container>
                    </TabPane>
                    <TabPane tabId="3" className='p-4'>
                        <Container>
                            <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.Summary && productItem.Summary}}></div>
                        </Container>
                    </TabPane>
                    <TabPane tabId="4" className='p-4'>
                        <Container>
                            <div className='innerWrapper'>
                                <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.MoreDetails && productItem.MoreDetails}}></div>
                            </div>
                        </Container>
                    </TabPane>
                    <TabPane tabId="5" className='p-4'>
                        <Container>
                            <div className='innerWrapper'>
                                <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.tnc && productItem.tnc}}></div>
                            </div>
                        </Container>
                    </TabPane>
                    <TabPane tabId="6" className='p-4'>
                        <Container>
                            <div className='innerWrapper'>
                                <div className='innerWrapper' dangerouslySetInnerHTML={{__html:productItem && productItem.Applications && productItem.Applications}}></div>
                            </div>
                        </Container>
                    </TabPane>
                </TabContent>
                </Container>
                    
                </div>
                <MakeAnOffer isOpen={offerOpen} setIsOpen={setOfferOpen}
                    productId={productItem?._id}
                    productType="tech"
                    bidFor={productItem?.productTitle}
                />
                <SubmitEnquiry isOpen={enquiryOpen} setIsOpen={setEnquiryOpen}
                    productId={productItem?._id}
                    productType="tech"
                />
                <BuyNow isOpen={buyNowOpen} setIsOpen={setBuyNowOpen} 
                    // productDetail={productItem}
                    saleType={productItem?.saleType}
                    id={productItem?._id}
                    price={price}
                    prductType="TP"
                    productName={productItem?.productTitle}
                    description={productItem?.patentOwner}
                    image={`${IMG_URL}${productItem && productItem?.imgUrl}`}
                    licenseType={licenseType}
                />
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);