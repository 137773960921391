import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { connect } from "react-redux";
import './brands.scss'
import textAreaLeft from '../../Assets/big_input_left.png'
import textAreaRight from '../../Assets/big_input_right.png'
import { BRAND_SALE } from '../../Store/actions/submitForm'
import {toast} from 'react-toastify'

const mapStateToProps = (state) => ({
    brandSale: state.submitForm.brandSale,
    userToken:state.auth.userToken
});
const mapDispatchToProps = (dispatch) => ({
    BRAND_SALE: (payload) => dispatch(BRAND_SALE(payload))
});


const BrandSale = (props) => {

    const [newFormData, setNewFormData] = useState({});
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(()=>{
        if(props.brandSale){
            setIsSuccess(true)
            setTimeout(() => {
                closeForm()
                setIsSuccess(false)
            }, 3000);
        }
        // eslint-disable-next-line
    }, [props.brandSale])

    const closeForm = () => {
        props.setIsOpen(false)
    }

    const setFormData = (e) => {
        const { name, value } = e.target
        const formData = newFormData
        formData[name] = value
        setNewFormData(
            formData
        )
    }
    const protectBrandData = () => {
        const {description} = newFormData
        if(!description){
            toast.error("Please fill All Field")
            return
        }else{
        const payload = {
            ...newFormData,
            userId:props.userToken.userId,
            adminName:"Info",
            adminEmail:"info@honeybeezz.co"
          }
          // console.log(payload);
          props.BRAND_SALE(payload)
        }
    }
    return (
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen}
            // toggle={()=>props.REGISTRATION(false)}
            >
                {isSuccess ?
                <Fragment>
                    <div className='isSuccess'>
                    <h2>Thank you</h2>
                    <h4>for sharing your requirements with us.<br/>
                        We will contact you shortly.</h4>
                    </div>
                </Fragment> :<Fragment>
                    <ModalHeader toggle={closeForm}>
                        Brand for Sale!
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <div className='inputWrapper m-0'>
                                            <img src={textAreaLeft} alt="" className='biginputLeft' />
                                            <Input type='textarea' placeholder='Please share details of Technology you are seeking' className='biginputarea' name="description" onChange={setFormData} />
                                            <img src={textAreaRight} alt="" className='biginputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={12} className="text-center mt-n1">
                                        <Button className='cta_btn' onClick={protectBrandData}> <h5>Submit</h5> </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                </Fragment>}
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandSale);