import React, { Fragment } from 'react'
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardImg, CardText, CardTitle, Container, Row, Col } from 'reactstrap';
import { connect } from "react-redux";
import './style.scss'
// import productImg from '../../Assets/product_img.png'
import { IMG_URL } from '../../Helpers/constant';
import { SET_PRODUCT_ID } from '../../Store/actions/product';
import Loader from '../../Layout/Loader';
const mapStateToProps = (state) => ({
    brandProductList: state.Product.brandProductList
});

const mapDispatchToProps = (dispatch) => ({
    SET_PRODUCT_ID: (payload) => dispatch(SET_PRODUCT_ID(payload)),
});

const BrandProductList = (props) => {
    // const [isLoading, setLoading] = useState(false)
    let navigate = useNavigate();

    const goToProductDetail = (item) => {
        props.SET_PRODUCT_ID(item)
        navigate('/brandProductDetails')
    }
    // useEffect(()=>{
    //     if(props.brandProductList){
    //         setLoading(true)
    //     }
    //     // eslint-disable-next-line
    // },[])

    const { brandProductList } = props
    return (
        <Fragment>
            <Container>
                <h2 className='page_heading'>Product List</h2>
                <Row>
                    {brandProductList ? brandProductList.length ? brandProductList.map((item, index) => {
                        return <Col md={6} lg={3} className="mb-4 cursor" key={index} onClick={() => goToProductDetail(item)}>
                            <Card>
                                <CardImg alt='' src={`${IMG_URL}${item.imgUrl}`} width="100%" top />
                                <CardBody>
                                    <CardTitle tag="h5">
                                        {item.productTitle}
                                    </CardTitle>
                                    <CardText>
                                        <div dangerouslySetInnerHTML={{ __html: item.moreDetails && item.moreDetails }}></div>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    }):<div className='noProduct'>
                    <div className='contantWrapper'>
                        <h2>Oops</h2>
                        <h5>No Result Found</h5>
                    </div>
                </div>:<Loader/>}
                </Row>
            </Container>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandProductList);