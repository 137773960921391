import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button, FormGroup, Label } from 'reactstrap';
import { connect } from "react-redux";
import './technology.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import textAreaLeft from '../../Assets/input_1.png'
import textAreaRight from '../../Assets/input_2.png'
import { PROTECT_TECHNOLOGY } from '../../Store/actions/submitForm'
import {toast} from 'react-toastify'

const mapStateToProps = (state) => ({
    techProtected: state.submitForm.techProtected,
    userToken:state.auth.userToken
});
const mapDispatchToProps = (dispatch) => ({
    PROTECT_TECHNOLOGY: (payload) => dispatch(PROTECT_TECHNOLOGY(payload))
});


const ProtectTechnology = (props) => {

    const [newFormData, setNewFormData] = useState({});
    const [isSuccess, setIsSuccess] = useState(false)
    
    useEffect(()=>{
        if(props.techProtected){
            setIsSuccess(true)
            setTimeout(() => {
                closeForm();
                setIsSuccess(false)
            }, 3000);
        }
        // eslint-disable-next-line
    }, [props.techProtected])
    
    const closeForm = () => {
        props.setIsOpen(false)
    }

    const setFormData = (e) => {
        const { name, value } = e.target
        const formData = newFormData
        formData[name] = value
        setNewFormData(
            formData
        )
    }
    const protectTechnologyData = () => {
        const {techField, applicationType, techFor} = newFormData
        if(!techField || !applicationType || !techFor){
            toast.error("Please fill All Field")
            return
        }else{
        const payload = {
            ...newFormData,
            userId:props.userToken.userId,
            adminName:"Info",
            adminEmail:"info@honeybeezz.co"
        }
        console.log(payload)
        props.PROTECT_TECHNOLOGY(payload)
        }
    }
    return (
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen}
            // toggle={()=>props.REGISTRATION(false)}
            >
                {isSuccess ?
                <Fragment>
                    <div className='isSuccess'>
                        <h2>Thank you</h2>
                        <h4>for sharing your requirements with us.<br/>
                        We will contact you shortly.</h4>
                    </div>
                </Fragment> :<Fragment>
                    <ModalHeader toggle={closeForm}>
                        Protect your Technology
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs={{ size: 8, offset: 2 }}>
                                <Row>
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={leftImg} alt="" className='inputLeft' />
                                            <Input type='text' placeholder='Technical Field*' className='inputField' name="techField" onChange={setFormData} />
                                            <img src={rightImg} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={leftImg} alt="" className='inputLeft' />
                                            <Input type='text' placeholder='Type of Application*' className='inputField' name="applicationType" onChange={setFormData} />
                                            <img src={rightImg} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={5} className='txt-white'>
                                        Looking for*
                                    </Col>
                                    <Col xs={7}>
                                        <FormGroup tag="fieldset">
                                            <FormGroup check>
                                                <Input name="techFor" type="radio" onChange={setFormData} value="Patent"/>
                                                <Label check className='txt-white'>
                                                    Patent
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input name="techFor" type="radio" onChange={setFormData} value="IPR"/>
                                                <Label check className='txt-white'>
                                                    Design
                                                </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={leftImg} alt="" className='inputLeft' />
                                            <Input type='text' placeholder='Country interested' className='inputField' name="intrstdCountry" onChange={setFormData} />
                                            <img src={rightImg} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={leftImg} alt="" className='inputLeft' />
                                            <Input type='text' placeholder='Previous Publications(if any)' className='inputField' name="prevPublication" onChange={setFormData} />
                                            <img src={rightImg} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={textAreaLeft} alt="" className='inputLeft' />
                                            <Input type='textarea' placeholder='Comments/Message' className='inputarea' name="msg" onChange={setFormData} />
                                            <img src={textAreaRight} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={12} className="text-center mt-4">
                                        <Button className='cta_btn' onClick={protectTechnologyData}> <h5>Go</h5> </Button>
                                    </Col>
                                    <Col xs={12} className="mt-4 mendet">
                                        * mark field are mendetory
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        
                    </ModalBody>
                </Fragment>}
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProtectTechnology);