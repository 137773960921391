import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import reducer from '../Store/reducers'
import thunk from 'redux-thunk';

import {createLogger} from 'redux-logger'
const logger = createLogger();
const envt = process.env.REACT_APP_ENVIROMENT
// console.log(envt)
let  allStoreEnhancers
if(envt !== "LIVE"){
  allStoreEnhancers = compose(
    applyMiddleware(thunk, logger),
    window.devToolsExtension ? window.devToolsExtension() : f => f
)
}else{
  allStoreEnhancers = compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
  
}


export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducer
    }),
    allStoreEnhancers,
  );
}