import React, { Fragment, useState } from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { connect } from "react-redux";
// import './technology.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import textAreaLeft from '../../Assets/input_1.png'
import textAreaRight from '../../Assets/input_2.png'
// import { TECHNOLOGY_SALE } from '../../Store/actions/submitForm'
import {MAKE_AN_OFFER} from '../../Store/actions/product'
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router';

const mapStateToProps = (state) => ({
    techSale: state.submitForm.techSale,
    userToken:state.auth.userToken
});
const mapDispatchToProps = (dispatch) => ({
    MAKE_AN_OFFER: (payload) => dispatch(MAKE_AN_OFFER(payload))
});


const MakeAnOffer = (props) => {
    const navigate = useNavigate()
    const [newFormData, setNewFormData] = useState({});
    const [isSuccess, setIsSuccess] = useState(false)
    const {productId, productType, bidFor} = props
    
    const closeForm = () => {
        props.setIsOpen(false)
    }

    const setFormData = (e) => {
        const { name, value } = e.target
        setNewFormData(state=>({
            ...state,
            [name]:value
        }))
    }
    const submitFormData = () => {
        const {type, bidAmount} = newFormData
        if(!type || !bidAmount){
            toast.error('Please fill All Field')
            return
        }else{
        
          const payload = {
            ...newFormData,
            "productId":productId,
            "userId": props.userToken.userId,
            "bidFor": bidFor,
            "productType": productType,
        }

          props.MAKE_AN_OFFER(payload).then(res=>{
            setIsSuccess(true)
            setTimeout(() => {
                setIsSuccess(false)
                navigate(-1)
            }, 3000);
          })
          
        }
    }
    return (
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen}>
                {isSuccess ?
                <Fragment>
                    <div className='isSuccess'>
                    <h2>Thank you</h2>
                    <h4>for sharing your requirements with us.<br/>
                        We will contact you shortly.</h4>
                    </div>
                </Fragment> :<Fragment>
                    <ModalHeader toggle={closeForm}>
                        <span style={{fontSize:"3.5rem"}}>Make an Offer</span>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xl={{size:8, offset:2}}>
                                <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12}>
                                            <div className='inputWrapper'>
                                                <img src={leftImg} alt="" className='inputLeft'/>
                                                <Input type='text' placeholder='Rs.' className='inputField' name="bidAmount" onChange={setFormData}/>
                                                <img src={rightImg} alt="" className='inputRight'/>
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <div className='inputWrapper'>
                                                <img src={leftImg} alt="" className='inputLeft'/>
                                                <Input name="type" type="select"  className='inputField' onChange={setFormData}>
                                                <option> Kind of Offer </option>
                                                <option value="sales"> Sales </option>
                                                <option value="license"> Non Exclusive License </option>
                                                <option value="ExLicense"> Exclusive License </option>
                                                </Input>
                                                <img src={rightImg} alt="" className='inputRight'/>
                                            </div>
                                        </Col>    
                                        <Col xs={12}>
                                            <div className='inputWrapper'>
                                                <img src={textAreaLeft} alt="" className='inputLeft'/>
                                                <Input type='textarea' placeholder='Comments/Message' className='inputarea' name="msg" onChange={setFormData}/>
                                                <img src={textAreaRight} alt="" className='inputRight'/>
                                            </div>
                                        </Col>
                                            <Col xs={12} className="text-center">
                                                <Button className='cta_btn' onClick={submitFormData}> <h5>Go</h5> </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                    
                            </Col>
                        </Row>    
                       
                    </ModalBody>
                </Fragment>}
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(MakeAnOffer);