import * as Types from '../../Constents/actionTypes'
import SubmitForm from '../../Models/submitForm'

export const ADD_NEW_TECHNOLOGY = (payload) => async (dispatch) =>{
    try {
        const response = await SubmitForm.addListTechnology(payload)
        dispatch({
            type: Types.ADD_NEW_TECHNOLOGY,
            payload : response
        })
    } catch (exception) {
        dispatch({
            type: Types.ADD_NEW_TECHNOLOGY_ERROR,
            exception
        })
    }
}

export const PROTECT_TECHNOLOGY = (payload) => async (dispatch) =>{
    try {
        const response = await SubmitForm.protectTechnology(payload)
        dispatch({
            type: Types.PROTECT_TECHNOLOGY,
            payload : response
        })
    } catch (exception) {
        dispatch({
            type: Types.PROTECT_TECHNOLOGY_ERROR,
            exception
        })
    }
}

export const TECHNOLOGY_SALE = (payload) => async (dispatch) =>{
    try {
        const response = await SubmitForm.technologySale(payload)
        dispatch({
            type: Types.TECHNOLOGY_SALE,
            payload : response
        })
    } catch (exception) {
        dispatch({
            type: Types.TECHNOLOGY_SALE_ERROR,
            exception
        })
    }
}

export const PROTECT_BRAND = (payload) => async (dispatch) =>{
    try {
        const response = await SubmitForm.protectBrand(payload)
        dispatch({
            type: Types.PROTECT_BRAND,
            payload : response
        })
    } catch (exception) {
        dispatch({
            type: Types.PROTECT_BRAND_ERROR,
            exception
        })
    }
}

export const ADD_NEW_BRAND = (payload) => async (dispatch) =>{
    try {
        const response = await SubmitForm.addBrand(payload)
        dispatch({
            type: Types.ADD_NEW_BRAND,
            payload : response
        })
    } catch (exception) {
        dispatch({
            type: Types.ADD_NEW_BRAND_ERROR,
            exception
        })
    }
}

export const BRAND_SALE = (payload) => async (dispatch) =>{
    try {
        const response = await SubmitForm.brandSale(payload)
        dispatch({
            type: Types.BRAND_SALE,
            payload : response
        })
    } catch (exception) {
        dispatch({
            type: Types.BRAND_SALE_ERROR,
            exception
        })
    }
}