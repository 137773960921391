import React, { useEffect, useState, } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { connect } from 'react-redux'
import Loader from '../Loader';
import Home from '../../Components/Home';
import Technology from '../../Components/Technology';
import Brands from '../../Components/Brands';
import AppHeader from '../AppHeader';
import AppFooter from '../AppFooter';
import {SET_IS_USER_AUTHENTICATED, LOGOUT} from '../../Store/actions/auth'
import {GET_STATIC_PAGES} from '../../Store/actions/users'
import Auth from '../../Models/Auth';
import { ToastContainer } from 'react-toastify';
import AllCategory from '../../Components/Technology/allCategory';
import ProductsList from '../../Components/ProductsList';
import BrandProductsList from '../../Components/BrandProductsList';
import ProductDetails from '../../Components/ProductDetails';
import BrandProductDetails from '../../Components/BrandProductDetails';
import Profile from '../../Components/Profile';
import AllBrands from '../../Components/Brands/allBrands';
import About from '../../Components/About';
import Contact from '../../Components/Contact';
import Terms from '../../Components/Terms';
import Privacy from '../../Components/Privacy';
import FAQ from '../../Components/FAQ';
import Search from '../../Components/Search';
import VerifyEmail from '../../Components/verify-email';
import Verification from '../../Components/verification';
import Orders from '../../Components/Orders'
// import routes,{renderRoutes} from '../../router';
const mapStateToProps = state => {
    return {
        isUserAuthenticated: state.auth.isUserAuthenticated
    }
};

const mapDispatchToProps = (dispatch) => ({
    SET_IS_USER_AUTHENTICATED:(isUserAuthenticated, userDetails)=> dispatch(SET_IS_USER_AUTHENTICATED(isUserAuthenticated, userDetails)),
    LOGOUT:()=> dispatch(LOGOUT()),
    GET_STATIC_PAGES:()=>dispatch(GET_STATIC_PAGES()),
  });

  const AppMain = (props) =>{
    let location = useLocation();
    const [isLoading, setIsLoading]  = useState(true)
    
    useEffect(() => {
        setIsLoading(false)
        props.GET_STATIC_PAGES()
        if (!props.isUserAuthenticated) {
            if (!Auth.data) {
                props.LOGOUT()
            } else {
                props.SET_IS_USER_AUTHENTICATED(true, Auth.data)
            }
        }
        // eslint-disable-next-line
    }, []);
    return(
        <div className={ location.pathname === "/"?"home":null || location.pathname === "/allcategory" ? "categorypage": null || location.pathname === "/technology" ? "technology": null || location.pathname === "/allbrands" ? "brand_categorypage": null }>
            {isLoading && <Loader />}
            <AppHeader/>
            <Routes>
                <Route exact path='/' caseSensitive={false} element={<Home/>} />
                <Route exact path='/technology' caseSensitive={false} element={<Technology/>} />
                <Route exact path='/brands' caseSensitive={false} element={<Brands/>} />
                <Route exact path='/allcategory' element={<AllCategory/>} />
                <Route exact path='/allbrands' element={<AllBrands/>} />
                <Route exact path='/productList' element={<ProductsList/>} />
                <Route exact path='/brandproductList' element={<BrandProductsList/>} />
                <Route exact path='/productDetails' element={<ProductDetails/>} />
                <Route exact path='/brandProductDetails' element={<BrandProductDetails/>} />
                <Route exact path='/profile' element={<Profile/>} />
                <Route exact path='/orders' element={<Orders/>} />
                <Route exact path='/about' element={<About/>} />
                <Route exact path='/contact' element={<Contact/>} />
                <Route exact path='/terms' element={<Terms/>} />
                <Route exact path='/privacy' element={<Privacy/>} />
                <Route exact path='/faq' element={<FAQ/>} />
                <Route exact path='/search' element={<Search/>} />
                <Route exact path='/verify-email' element={<VerifyEmail/>} />
                <Route exact path='/verification' element={<Verification/>} />
            </Routes>
            {/* {renderRoutes(routes)} */}
            <AppFooter/>
            <ToastContainer/>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(AppMain);