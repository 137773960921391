import React, { Fragment, useState, useEffect} from 'react'
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GET_TECH_PRODUCT_LIST, GET_BRAND_PRODUCT_LIST } from '../../Store/actions/product';
import {GET_TECH_CATEGORY_LIST, GET_BRAND_CATEGORY_LIST} from '../../Store/actions/category'
import Accordion from '../Accordion';


const mapStateToProps = (state) => ({
    techSale: state.submitForm.techSale,
    userToken:state.auth.userToken,
    techCategoryList:state.Category.techCategoryList,
    brandCategoryList:state.Category.brandCategoryList
});
const mapDispatchToProps = (dispatch) => ({
    GET_TECH_PRODUCT_LIST:(cateId)=> dispatch(GET_TECH_PRODUCT_LIST(cateId)),
    GET_TECH_CATEGORY_LIST:()=>dispatch(GET_TECH_CATEGORY_LIST()),
    GET_BRAND_PRODUCT_LIST:(cateId)=> dispatch(GET_BRAND_PRODUCT_LIST(cateId)),
    GET_BRAND_CATEGORY_LIST:()=>dispatch(GET_BRAND_CATEGORY_LIST())
});

const Categories =(props)=>{
    const [activeTab, setActiveTab] = useState("1")

    let navigate = useNavigate();
    // const [isOpen, setIsOpen] = useState(false)
    // const [techName, setTechName]  = useState("")
    // const [modalData, setModalData] = useState([])
    // const [modalactiveTab, setModalActiveTab] = useState(0)

    // const toggleModalTab = (tabId)=>{
    //     setModalActiveTab(tabId)
    // }

    const toggleTab = (tabId)=>{
        setActiveTab(tabId)
    }
    // const closeForm =()=>{
    //     setIsOpen(false)
    // }
    useEffect(()=>{
        props.GET_TECH_CATEGORY_LIST()
        props.GET_BRAND_CATEGORY_LIST()
        // eslint-disable-next-line
    },[])

    // const openModal = (name, data)=>{
    //     setTechName(name)
    //     setModalData(data)
    //     setIsOpen(true)
    // }

    const goToProductList = (type, cateId)=>{
        const payload = {
            "id" : cateId,
            "type":type
        }
        props.GET_TECH_PRODUCT_LIST(payload)
        navigate('/productList')
    }
    const goToBrandProductList = (type, cateId)=>{
        const payload = {
            "id" : cateId,
            "type":type
        }
        props.GET_BRAND_PRODUCT_LIST(payload)
        navigate('/brandproductList')
    }
    // console.log(modalData)
    const {techCategoryList, brandCategoryList} = props
    return(
        <Fragment>
            <Row>
                <Col sm={4} md={4} lg={4} xl={4} className="d-flex align-items-center justify-content-end">
                    <h1 className='heading'>Hive</h1>
                </Col>
                <Col sm={4} md={{offset: 1,  size: 6 }} lg={{offset: 1,  size: 6 }} xl={{offset: 1,  size: 6 }} className="Categories_tabs">
                <Nav tabs>
                    <NavItem>
                    <NavLink className={activeTab === "1"?"active":""} onClick={() => toggleTab("1") }>
                        Technology
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink className={activeTab === "2"?"active":""} onClick={() => toggleTab("2") }>
                        Brands
                    </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="categories_wrapper">
                    <TabPane tabId="1" className='p-4'>
                        <Row>
                            <Col>
                            
                                <Accordion technologiesData={techCategoryList} goToProductList={goToProductList}/>    
                            
                                {/* <ul className='categories_list'>
                                {techCategoryList && techCategoryList.length && techCategoryList.map((item, index)=>{
                                    return <li key={index} onClick={()=>openModal(item.name, item.subCategory)}>{item.name} <span>({item.subCategory.length})</span></li>
                                })}
                                </ul> */}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2" className='p-4'>
                        <Row>
                            <Col>
                                <ul className='categories_list'>
                                {brandCategoryList && brandCategoryList.length && brandCategoryList.map((item, index)=>{
                                        // return <li key={index} onClick={()=>openModal(item.name, item.subCategory)}>{item.name}</li>
                                        return <li key={index} onClick={()=>goToBrandProductList("CATEGORY", item._id)}>{item.name.toLowerCase()}</li>
                                    })}
                                </ul>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
                </Col>
            </Row>
            {/* <Modal size="lg" isOpen={isOpen} className="cate_modal">
                <ModalHeader toggle={closeForm}>
                    {techName}
                </ModalHeader>
                <ModalBody>
                
                {modalData && modalData[0] && modalData[0].technologies && modalData[0].technologies.length > 0 ? 
                <Nav tabs className='cateList_tab'>   
                {modalData.map((item, index)=>{ return <Fragment>
                    <NavItem key={index}>
                    <NavLink className={activeTab === index?"active":""} onClick={() => toggleModalTab(index) }>
                        {item.name}
                    </NavLink>
                    </NavItem>
                    </Fragment>
                })}
                </Nav>
                : 
                <ul className='sub_categories_list'>   
                {modalData.map((item, index)=>{
                    return <li key={index} onClick={()=>goToProductList("SUB_CATEGORY", item._id)}>{item.name}</li>
                })}
                </ul>}
                {modalData && modalData[0] && modalData[0].technologies && modalData[0].technologies.length > 0? 
                    <TabContent activeTab={modalactiveTab} className="categories_wrapper">
                        {modalData.map((item, index)=>{
                        return <Fragment>
                            <TabPane tabId={index} className='p-4'>
                                <ul className='categories_list'>
                                {item.technologies.map((subItem, index)=>{
                                    return <li key={index} onClick={()=>goToProductList("SUB_CATEGORY", subItem._id)}>{subItem.name}</li>
                                })}
                                </ul>
                            </TabPane> 
                            </Fragment>
                        })}
                </TabContent>
                :null}
                      

                 
                </ModalBody>  
            </Modal> */}
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Categories);