import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import{NavbarToggler, Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, InputGroup, Button} from 'reactstrap'
import {REGISTRATION, CLEAR_MSG} from '../../Store/actions/users'
import Registration from "../../Components/Registration";
import ForgetPassword from "../../Components/ForgetPassword";
import { GET_ALL_BRAND_PRODUCT_LIST, GET_ALL_TECH_PRODUCT_LIST, SEARCH_DATA } from "../../Store/actions/product";
import {GET_BRAND_CATEGORY_LIST} from '../../Store/actions/category'
import {GET_TECH_CATEGORY_LIST} from '../../Store/actions/category'
import { SearchIconPro } from '../../Components/Icons';
import {toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

const mapStateToProps = (state) => ({
  isUserAuthenticated: state.auth.isUserAuthenticated, 
  userToken: state.auth.userToken,
  addedUser: state.users.addedUser,
  errorMsg: state.users.errorMsg
});

const mapDispatchToProps = (dispatch) => ({
  REGISTRATION:(payload)=> dispatch(REGISTRATION(payload)),
  GET_ALL_BRAND_PRODUCT_LIST:()=>dispatch(GET_ALL_BRAND_PRODUCT_LIST()),
  GET_ALL_TECH_PRODUCT_LIST:()=>dispatch(GET_ALL_TECH_PRODUCT_LIST()),
  GET_BRAND_CATEGORY_LIST:()=>dispatch(GET_BRAND_CATEGORY_LIST()),
  GET_TECH_CATEGORY_LIST:()=>dispatch(GET_TECH_CATEGORY_LIST()),
  SEARCH_DATA:(payload)=>dispatch(SEARCH_DATA(payload)),
  CLEAR_MSG:()=> dispatch(CLEAR_MSG())
});

const AppNav = (props) => {
  const [userToken, setUserToken] = useState(null)
  const [searchData, setSearchData] = useState('')
  // const [successMsg, setSuccessMsg] = useState('')
  // const [errorMsg, setErrorMsg]=  useState('')
  const navigate = useNavigate();

  useEffect(()=>{
      props.GET_ALL_TECH_PRODUCT_LIST()
      props.GET_ALL_BRAND_PRODUCT_LIST()
      props.GET_BRAND_CATEGORY_LIST();
      props.GET_TECH_CATEGORY_LIST();
    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if(props.isUserAuthenticated){
      props.REGISTRATION(false)
      props.GET_ALL_TECH_PRODUCT_LIST()
      props.GET_ALL_BRAND_PRODUCT_LIST()
      props.GET_BRAND_CATEGORY_LIST();
      props.GET_TECH_CATEGORY_LIST();
    }
    // eslint-disable-next-line
  }, [props.isUserAuthenticated])
  
  useEffect(()=>{
    if(props.addedUser){
      toast.success(props.addedUser)
      props.CLEAR_MSG()
      // props.REGISTRATION(false)
    }
    // eslint-disable-next-line
  }, [props.addedUser])

  useEffect(()=>{
    if(props.errorMsg){
      toast.error(props.errorMsg)
      // setErrorMsg(props.errorMsg)
      // setTimeout(() => {
      //   setErrorMsg('')
      // }, 5000);
    }
    // eslint-disable-next-line
  }, [props.errorMsg])

  useEffect(()=>{
    if(props.userToken){
      setUserToken(props.userToken)
    }
    // eslint-disable-next-line
  }, [props.userToken])
  
  const onSearch =()=>{
    props.SEARCH_DATA(searchData)    
    navigate('/search')
  }

  return <Fragment>
      <NavbarToggler onClick={()=>props.menuFunc()} />
    <Collapse navbar className="main_nav" isOpen={props.toggle}>
      <Nav
        className="ms-auto"
        navbar
      >
        <NavItem>
          <NavLink href="/about">
            About 
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/contact">
            Contact
          </NavLink>
        </NavItem>
        <UncontrolledDropdown
          inNavbar
          nav
        >
          <DropdownToggle
            caret
            nav
          >
             IP Marketplace
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem href="/technology">
              Technology
            </DropdownItem>
            <DropdownItem href="/brands">
              Brands
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      {props.isUserAuthenticated ?
        <UncontrolledDropdown
          inNavbar
          nav
        >
          <DropdownToggle
            caret
            nav
          >
            {userToken && userToken.userName}
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem href="/profile">
              Profile
            </DropdownItem>
            <DropdownItem href="/orders">
              Order
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={props.logout}>
              Logout
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>:
        <NavItem>
          <NavLink className="cursor" onClick={()=>props.REGISTRATION(true)}>
              Login
          </NavLink>
        </NavItem>
}
    <NavItem>
      <InputGroup>
      <Input type="search" placeholder="Search" onChange={e=>setSearchData(e.target.value)}/>
      <Button color="primary" onClick={onSearch}>
         <SearchIconPro/>
      </Button>
    </InputGroup>
    </NavItem>
      </Nav>
    </Collapse>
    <Registration/>
    <ForgetPassword/>
    {/* {successMsg && <Alert className="userCreated">{successMsg}</Alert>} */}
    {/* {errorMsg && <Alert className="userCreated">{errorMsg}</Alert>} */}
  </Fragment>
};
export default connect(mapStateToProps, mapDispatchToProps)(AppNav);
