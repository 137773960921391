import React, { Fragment} from 'react'
import { Container, Button } from 'reactstrap';
import { connect } from "react-redux";
import {RESEND_EMAIL} from '../../Store/actions/users'

const mapStateToProps = (state) => ({
    userData:state.users.userData
});

const mapDispatchToProps = (dispatch) => ({
    RESEND_EMAIL:(payload)=>dispatch(RESEND_EMAIL(payload)),
  });

const Verification =(props)=>{

    const resendVerification =()=>{
        const payload ={
            email: props.userData.email,
            id: props.userData._id
        }
        props.RESEND_EMAIL(payload)
    }
    
    return(
        <Fragment>
                <Container className='verification'>
                    <div className='contantWrapper'>
                        <h2>Thank you &amp; Welcome </h2>
                        <h5>We sent a verification mail to your registered email id,<br/>
please verify and begin your journey with us.</h5>
<Button className='cta_btn_verification mt-4' onClick={resendVerification}> Resend Verification Email</Button>
                    </div>
                </Container>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Verification);