import React, { Fragment, useEffect, useState} from 'react'
import { Row, Col } from 'reactstrap';
import Patent1 from '../../Assets/Patent-1.png'
import Patent2 from '../../Assets/Patent-2.png'
import Patent3 from '../../Assets/Patent-3.png'

import AboutImg from '../../Assets/aboutus.jpg'

import Slider from "react-slick";
import { connect } from "react-redux";

import './showcase.scss'
import { useNavigate } from 'react-router';
import { SET_PRODUCT_ID } from '../../Store/actions/product';

const mapStateToProps = (state) => ({
  allTechproductLits: state.Product.allTechproductLits,
});

const mapDispatchToProps = (dispatch) => ({
  SET_PRODUCT_ID:(payload)=> dispatch(SET_PRODUCT_ID(payload)),
});

const ShowCase =(props)=>{
    let navigate = useNavigate();
    const goToProductDetail = (item)=>{
        props.SET_PRODUCT_ID(item)
        navigate('/ProductDetails')
    }
    
    
    const {allTechproductLits} = props
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
      // const items = [
      //   {
      //       Patent:AboutImg,
      //       heading:"Lorem ipsum dolor",
      //       content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh"
      //   },
      //   {
      //       Patent:Patent2,
      //       heading:"Lorem ipsum dolor",
      //       content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh"
      //   },
      //   {
      //       Patent:Patent3,
      //       heading:"Lorem ipsum dolor",
      //       content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh"
      //   },{
      //       Patent:Patent1,
      //       heading:"Lorem ipsum dolor",
      //       content:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh"
      //   },

      // ];  
      // const slides = items && items.length && items.map((item, index) => {
      //   return (
      //       <div key={index} className="contantWrapper">
      //           {/* <div className='hexagon3'></div> */}
      //           <div className="patent">
      //             <div className='shape-image'></div>
      //             <img src={item.Patent} alt='' className='img-fluid'/>
      //           </div>
                
      //           <div className='content'>
      //               <h4>{item.heading}</h4>
      //               <p>{item.content}</p>
      //           </div>
      //       </div>
      //   );
      // });

      const slides = allTechproductLits && allTechproductLits.length && allTechproductLits.map((item, index) => {
        return (
            <div key={index} className="contantWrapper">
                {/* <div className='hexagon3'></div> */}
                <div className="patent">
                  <div className='shape-image'></div>
                  <img src={`https://honeybeezz.co/api${item.imgUrl}`} alt=''/>
                </div>
                
                <div className='content'>
                    <h4>{item.productTitle}</h4>
                    <p dangerouslySetInnerHTML={{__html:item.Summary && item.Summary}}></p>
                </div>
            </div>
        );
      });
    return(
        <Fragment>
            <Row>
                <Col sm={3} md={3} lg={3} xl={3} className="d-flex align-items-end">
                    <h2 className='heading'>Showcase Patent</h2>
                </Col>
                <Col sm={8} md={8} lg={8} xl={8}>
                    <Slider {...settings} className="showcase-carousel">
                        {slides}
                    </Slider>  
                </Col>
            </Row>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ShowCase);