import React, { Fragment, useEffect } from 'react'
import { Row, Col, Container} from 'reactstrap';
import { connect } from "react-redux";
import { GET_USER_DETAIL } from '../../Store/actions/users';
import './style.scss'
import Edit from '../../Assets/edit.png'
import ChangePassword from '../ChangePassword';
import { useState } from 'react';
import EditProfile from '../EditProfile';
const mapStateToProps = (state) => ({
    userToken:state.auth.userToken,
    userDetails:state.users.userDetails
});
const mapDispatchToProps = (dispatch) => ({
    GET_USER_DETAIL:(userId)=> dispatch(GET_USER_DETAIL(userId)),
});

const Profile = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [profileEdit, setProfile] = useState(false)
    const closeForm =()=>{
        setIsOpen(!isOpen)
    }
    useEffect(()=>{
        if(props.userToken){
            props.GET_USER_DETAIL(props.userToken.userId)
        }
        // eslint-disable-next-line
    },[props.userToken])
    
    const editProfile =()=>{
        setProfile(!profileEdit)
    }
    const {userDetails} = props
    // console.log(userDetails)
    return (
        <Fragment>
            <Container className='userProfile'>
            <Row>
                <Col sm={12} md={9} lg={9} xl={9} className="m-4">
                    <h2>Hi {userDetails && userDetails.firstName}! What do you want to update?</h2>
                    <Row className='profileDetail'>
                        <Col xl={6}>
                            <Row className='fieldRow'>
                                <Col xs={4}>Email</Col>
                                <Col xs={7} className="data">{userDetails && userDetails.email}</Col>
                                <Col xs={1} className="p-0"><img src={Edit} alt="" className='cursor' onClick={editProfile}/></Col>
                            </Row>
                            <Row className='fieldRow'>
                                <Col xs={4}>Password</Col>
                                <Col xs={7} className="data">********</Col>
                                <Col xs={1} className="p-0"><img src={Edit} alt="" className='cursor' onClick={closeForm}/></Col>
                            </Row>
                            <Row className='fieldRow'>
                                <Col xs={4}>Full Name</Col>
                                <Col xs={7} className="data">{userDetails && userDetails.firstName}{''} {userDetails && userDetails.lastName}</Col>
                                <Col xs={1} className="p-0"><img src={Edit} alt="" className='cursor' onClick={editProfile}/></Col>
                            </Row>
                            <Row className='fieldRow'>
                                <Col xs={4}>Phone</Col>
                                <Col xs={7} className="data">{userDetails && userDetails.phoneNumber}</Col>
                                <Col xs={1} className="p-0"><img src={Edit} alt="" className='cursor' onClick={editProfile}/></Col>
                            </Row>
                            <Row className='fieldRow'>
                                <Col xs={4}>Organisation</Col>
                                <Col xs={7} className="data">{userDetails && userDetails.email}</Col>
                                <Col xs={1} className="p-0">
                                    {/* <img src={Edit} alt=""/> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>  
                </Col>
            </Row>
            <div className='patentList'>
                <div className='Item'><span>2</span> Patents</div>
                <div className='Item'><span>3</span> IPR</div>
                <div className='Item'><span>1</span> Brand Purchased</div>
            </div>
            </Container>
            <ChangePassword isOpen={isOpen} closeForm={closeForm} userDetails={userDetails}/>
            <EditProfile isOpen={profileEdit} closeForm={editProfile} userDetails={userDetails}/>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);