import Axios from "./Axios";
import {  API_URL, API_REQUESTS } from '../Helpers/constant'

class Category extends Axios {
    
    static async getTechCategoryList() {
        const USER_URL = `${API_URL}${API_REQUESTS.TECHNOLOGIS}`
        return new Promise((resolve,reject)=>{
            this.axios.get(USER_URL).then((categoryDate)=> {
                if(categoryDate.data) {
                    return resolve(categoryDate.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async getBrandCategoryList() {
        const USER_URL = `${API_URL}${API_REQUESTS.BRANDS}`
        return new Promise((resolve,reject)=>{
            this.axios.get(USER_URL).then((categoryDate)=> {
                if(categoryDate.data) {
                    return resolve(categoryDate.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

}
export default Category;