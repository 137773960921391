import React, { Fragment, useState} from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { connect } from "react-redux";
// import './registration.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import {USER_FORGOT_PASSWORD, FORGOT_PASSWORD, FORGOT_PASSWORD_ERROR, RESET_PASSWORD, CLEAR_RESET} from '../../Store/actions/users'

import { useEffect } from 'react';
// import Captcha from './Captcha'

const mapStateToProps = (state) => ({
    isOpenForgotPassword: state.users.isOpenForgotPassword,
    OptSend:state.users.OptSend,
    forgotPswdError:state.users.forgotPswdError,
    passwordReset:state.users.passwordReset
});
const mapDispatchToProps = (dispatch) => ({
    USER_FORGOT_PASSWORD:(payload)=>dispatch(USER_FORGOT_PASSWORD(payload)),
    FORGOT_PASSWORD:(payload)=>dispatch(FORGOT_PASSWORD(payload)),
    FORGOT_PASSWORD_ERROR:(payload)=>dispatch(FORGOT_PASSWORD_ERROR(payload)),
    RESET_PASSWORD:(payload)=>dispatch(RESET_PASSWORD(payload)),
    CLEAR_RESET:()=> dispatch(CLEAR_RESET())
  });

  
const ForgetPassword =(props)=>{
    const [OtpEnable, setOptEnable] = useState(false)
    const [Otp, setOpt] = useState()
    const [errMessage, setErrMessage] = useState("")
    const [successMsg, setSuccessMsg] = useState("")
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState(""); 
    
    useEffect(()=>{
        if(props.OptSend){
            setOptEnable(true)
        }
    },[props.OptSend])

    useEffect(()=>{
        if(props.forgotPswdError) {
            setErrMessage(props.forgotPswdError)
         }
    },[props.forgotPswdError])

    useEffect(()=>{
        if(props.passwordReset) {
            setSuccessMsg(props.passwordReset)
            setOptEnable(false)
            setTimeout(() => {
                props.FORGOT_PASSWORD(false)
                props.CLEAR_RESET()  
                setSuccessMsg("")  
            }, 1000);
            
        }
        // eslint-disable-next-line
    },[props.passwordReset])

    /* Initiate Forgot Password functionality */
  const initiateForgotPassword = async () => {
    
    setErrMessage("")
    if (!email) {
      setErrMessage("Please provide Email")
      return;
    }
    const payload = {
      email
    }
    
    await props.USER_FORGOT_PASSWORD(payload)
    
  }

    const closeForm =()=>{
        props.FORGOT_PASSWORD(false)
    }
    const setData = e =>{
        const {name, value} = e.target
        if(name === 'email'){
            setEmail(value)
        }
        if(name === 'password'){
            setPassword(value)
        }
        if(name === 'confirmPassword'){
            setConfirmPassword(value)
        }
        if(name === 'otp'){
            setOpt(value)
        }
    }
    
    
    const doChangePssword =()=>{
        if(password !== confirmPassword){
            setErrMessage("Password not Match")
            return
        }
        const payload = {
            email: email,
            password: password,
            otp: Otp
          }
          props.RESET_PASSWORD(payload)
    }
    return(
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpenForgotPassword} 
            >
                {OtpEnable ?<Fragment>
                <ModalHeader toggle={closeForm}>
                    Forgot Password 
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={{size:8, offset:2}}>
                            <Row>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='password' placeholder='Password*' className='inputField' name="password" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='password' placeholder='Confirm Password*' className='inputField' name="confirmPassword" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='text' placeholder='otp' className='inputField' name="otp" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12} className="text-center mt-4">
                                    <Button className='cta_btn' onClick={() => doChangePssword()}> <h5>Submit</h5> </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                </Fragment>
                :<Fragment>
                    <ModalHeader toggle={closeForm}>
                        Forgot Password 
                    </ModalHeader>
                    <ModalBody>
                    <Row>
                        <Col xs={{size:8, offset:2}}>
                            <Row>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='email' placeholder='Email' className='inputField' name="email" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12} className="text-center mt-4">
                                <Button className='cta_btn' onClick={() => initiateForgotPassword()}> <h5>Submit</h5> </Button>
                                </Col>
                                <Col xs={12}>
                                { (errMessage) ? (
                                    <div className="mt-4 alert alert-danger text-center">{errMessage} * </div>
                                ): null
                                }
                                { (successMsg) ? (
                                    <div className="mt-4 alert alert-success text-center">{successMsg} * </div>
                                ): null
                                }
                                
                                </Col>
                            </Row>
                        
                        </Col>
                    </Row>
                    </ModalBody>
                </Fragment>

                }
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);