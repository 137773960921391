import * as Types from '../../Constents/actionTypes'
import ProductModal from '../../Models/product'


export const GET_ALL_TECH_PRODUCT_LIST = ()=> async (dispatch)=>{
    try {
        const response = await ProductModal.getAllTechProductList()
        dispatch({
            type:Types.ALL_TECH_PRODUCT_LIST,
            techProductList:response
        })
    } catch (error) {
        dispatch({
            type:Types.ALL_TECH_PRODUCT_LIST_ERROR,
            error
        })
    }
}

export const GET_ALL_BRAND_PRODUCT_LIST = ()=> async (dispatch)=>{
    try {
        const response = await ProductModal.getAllBrandProductList()
        dispatch({
            type:Types.ALL_BRAND_PRODUCT_LIST,
            brandProductList:response
        })
    } catch (error) {
        dispatch({
            type:Types.ALL_BRAND_PRODUCT_LIST_ERROR,
            error
        })
    }
}

export const GET_TECH_PRODUCT_LIST = (payload)=> async (dispatch)=>{
try {
    const response = await ProductModal.getTechProductList(payload)
    dispatch({
        type:Types.GET_TECH_PRODUCT_LIST,
        techProductList:response
    })
} catch (error) {
    dispatch({
        type:Types.GET_TECH_PRODUCT_LIST_ERROR,
        error
    })
}
}
export const GET_BRAND_PRODUCT_LIST = (payload)=> async (dispatch)=>{
    try {
        const response = await ProductModal.getBrandProductList(payload)
        dispatch({
            type:Types.GET_BRAND_PRODUCT_LIST,
            brandProductList:response
        })
    } catch (error) {
        dispatch({
            type:Types.GET_BRAND_PRODUCT_LIST_ERROR,
            error
        })
    }
    }

export const SET_PRODUCT_ID = (payload)=> async (dispatch)=>{
    dispatch({
        type:Types.SET_PRODUCT_ID,
        productItem:payload
    })
}
export const SEARCH_DATA = (payload) => async (dispatch)=>{
    dispatch({
        type:Types.SEARCH_DATA,
        searchData: payload
    })
}
export const CLEAR = ()=> async (dispatch)=>{
    dispatch({
        type:Types.CLEAR,
    })
}

export const BUY_NOW = (payload)=> async (dispatch)=>{
    try {
        const response = await ProductModal.displayRazorpay(payload)
        dispatch({
            type:Types.BUY_NOW,
            paymentIsSuccess:response
        })
    } catch (error) {
        dispatch({
            type:Types.BUY_NOW_ERROR,
            error
        })
    }
}



export const MAKE_AN_OFFER = (payload)=> async (dispatch)=>{
    try {
        const response = await ProductModal.makeAnOffer(payload)
        dispatch({
            type:Types.MAKE_AN_OFFER,
            offerSeccess:response
        })
    } catch (error) {
        dispatch({
            type:Types.MAKE_AN_OFFER_ERROR,
            error
        })
    }
}

export const SEND_ENQUIRY = (payload)=> async (dispatch)=>{
    try {
        const response = await ProductModal.sendEnquiry(payload)
        dispatch({
            type:Types.SEND_ENQUIRY,
            enquirySeccess:response
        })
    } catch (error) {
        dispatch({
            type:Types.SEND_ENQUIRY_ERROR,
            error
        })
    }
}