import Axios from "./Axios";
import {  API_URL, API_REQUESTS } from '../Helpers/constant'

class Users extends Axios {
    
    static async addNewUser(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.USERS}`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData){
                    const USER_VERIFY_URL = `${API_URL}user/varification/mail`
                    let verify_payload ={
                            email: payload.email,
                            id: userData.data.DATA._id
                    }

                    this.axios.post(USER_VERIFY_URL, verify_payload).then((mailData)=>{
                        if(mailData.data) {
                            let data = {
                                msg: mailData.data.DATA,
                                userData: userData.data.DATA
                            }
                            return resolve(data)
                        }
                    })
                }
            })
            .catch(exception => {
                return reject(exception.response.data)
            })
        })
    }
    static async resendMail(payload) {
        const USER_VERIFY_URL = `${API_URL}user/varification/mail`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_VERIFY_URL,payload).then((mailData)=> {
                    if(mailData.data) {
                        return resolve(mailData.data.DATA)
                    }
            })
            .catch(exception => {
                return reject(exception.response.data)
            })
        })
    }
    static async getUser(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.USERS}/${payload}`
        return new Promise((resolve,reject)=>{
            this.axios.get(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async updateUser(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.USERS}`
        return new Promise((resolve,reject)=>{
            this.axios.put(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async forgotPassword(payload) {
        const USER_URL = `${API_URL}user/forgot/password`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }
    static async resetPassword(payload) {
        const USER_URL = `${API_URL}user/reset/password`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }
    static async changePassword(payload) {
        const USER_URL = `${API_URL}user/update/password`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL,payload).then((userData)=> {
                if(userData.data) {
                    return resolve(userData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }
    static async getPageData() {
        const USER_URL = `${API_URL}users/config/details`
        return new Promise((resolve,reject)=>{
            this.axios.get(USER_URL).then((pageData)=> {
                if(pageData.data) {
                    return resolve(pageData.data.staticDetails)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async getTeamData() {
        const USER_URL = `${API_URL}users/config/details`
        return new Promise((resolve,reject)=>{
            this.axios.get(USER_URL).then((pageData)=> {
                if(pageData.data) {
                    // debugger
                    return resolve(pageData.data.hbzTeam)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async getClientData() {
        const USER_URL = `${API_URL}users/config/details`
        return new Promise((resolve,reject)=>{
            this.axios.get(USER_URL).then((pageData)=> {
                if(pageData.data) {
                    return resolve(pageData.data.hbzClients)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async getOrders(payload) {
        const USER_URL = `${API_URL}order/user`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL, payload).then((orderData)=> {
                if(orderData) {
                    return resolve(orderData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }
}
export default Users;