import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
    pageData:state.users.pageData
});

const FAQ =(props)=>{
    const [defaultData, setDefaultData] = useState('')
    useEffect(()=>{
        if(props.pageData){
            let data = props.pageData.find(oneData=>{
                return oneData.key === 'faq'
            })
            setDefaultData(data.value)
        }
    }, [props.pageData])
    return(
        <Fragment>
                <Container className='page_bg'>
                    <Row className='m-5 pt-5'>
                        <Col xl={{size:8, offset:2}}>
                            <h2 className='privacy-head'>FAQ's</h2>
                            <div dangerouslySetInnerHTML={{__html:defaultData && defaultData}}></div>
                        </Col>
                    </Row>
                    
                </Container>
        </Fragment>
    )
}
export default connect(mapStateToProps)(FAQ);