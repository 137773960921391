import React, { Fragment, useState } from 'react'
import {Container, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from "react-redux";
// import './style.scss'
import product_img from '../../Assets/product_img_details.png'
import { GET_ORDER } from '../../Store/actions/users';
import { useEffect } from 'react';
import moment from 'moment'
import { IMG_URL } from '../../Helpers/constant';
const mapStateToProps = (state) => ({
    orderData: state.users.orderData,
    allBrandproductLits:state.Product.allBrandproductLits,
    allTechproductLits:state.Product.allTechproductLits,
    techCategoryList:state.Category.techCategoryList,
    brandCategoryList:state.Category.brandCategoryList,
    userToken:state.auth.userToken,
});
const mapDispatchToProps = (dispatch) => ({
    GET_ORDER:(payload)=> dispatch(GET_ORDER(payload)),
});


const Orders =(props)=>{
    
    const [activeTab, setActiveTab] = useState("1")
    const [allProduct, setAllProduct] = useState()
    
    useEffect(()=>{
        if(props.userToken){
            const payload = {
                id:props.userToken.userId
            }
            props.GET_ORDER(payload)
        }
        // eslint-disable-next-line
    },[props.userToken])

    const toggleTab = (tabId)=>{
        setActiveTab(tabId)
    }

    useEffect(()=>{
            const {allBrandproductLits, allTechproductLits} = props
            if(allBrandproductLits && allTechproductLits){
            let allData = allBrandproductLits.concat(allTechproductLits)  
                setAllProduct(allData)
            }
        // eslint-disable-next-line
    }, [])
    
    const productDetail = (id) =>{
        let data
        if (allProduct) {
            data = allProduct.find(onedata => {
                return (onedata._id && onedata._id === id)
            }
        )
        console.log(data)
        
        return data         
        }
    }
    const {orderData} = props
    const productItemList = orderData && orderData.length && orderData.map((item, index)=>{
        return  <div className='order-item' key={index}>
        <div className='order_head'>
            <div>Order Placed<br/>
                <strong>{moment(item.orderDate).format('DD-MM-YYYY')}</strong>
            </div>
            <div>
                Order Fulfilled<br/>
                <strong>{moment(item.updatedAt).format('DD-MM-YYYY')}</strong>
            </div>
            <div>
                Total <br/>
                <strong>Rs. {item.amount}/-</strong>
            </div>
            <div className='text-right'>
                Order # {item._id} <br/>
            </div>
        </div>
        <div className='order_body'>
            <div>
                {allProduct && <img src={`${IMG_URL}${productDetail(item.productId).imgUrl}`} alt="" />}
            </div>
            <div>
                <h4>{allProduct && productDetail(item.productId).productTitle}</h4>
                <h5>{allProduct && productDetail(item.productId).saleType}</h5>
            </div>
        </div>
    </div>
    })

    return(
        <Fragment>
            <Container className='order-page'>
                <h2 className='order-head'>Your Order</h2>
                <div className='tab_heading'>
                    <Nav tabs>
                        <NavItem>
                        <NavLink className={activeTab === "1"?"active":""} onClick={() => toggleTab("1") }>
                            Orders
                        </NavLink>
                        </NavItem>
                        {/* <NavItem>
                        <NavLink className={activeTab === "2"?"active":""} onClick={() => toggleTab("2") }>
                            Cancelled Orders
                        </NavLink>
                        </NavItem> */}
                    </Nav>
                </div>
                <TabContent activeTab={activeTab} className="order-detail">
                    <TabPane tabId="1" className='p-4'>
                        <Container>
                            <span className='txt-big'>{orderData?.length} orders</span> placed in last 12 months
                                {productItemList}
                                {/* <div className='order-item'>
                                    <div className='order_head'>
                                        <div>
                                            Order Placed<br/>
                                            <strong>10 May 2022</strong>
                                        </div>
                                        <div>
                                            Order Fulfilled<br/>
                                            <strong>15 May 2022</strong>
                                        </div>
                                        <div>
                                            Total <br/>
                                            <strong>Rs. 10,000,000/-</strong>
                                        </div>
                                        <div className='text-right'>
                                            Order # 403-3455654-4289913 <br/>
                                        </div>
                                    </div>
                                    <div className='order_body'>
                                        <div>
                                            <img src={product_img} alt="" />
                                        </div>
                                        <div>
                                            <h4>Computer controlled mobile device</h4>
                                            <h5>Non-Exclusive License - 5 year</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className='order-item'>
                                    <div className='order_head'>
                                        <div>
                                            Order Placed<br/>
                                            <strong>10 May 2022</strong>
                                        </div>
                                        <div>
                                            Order Fulfilled<br/>
                                            <strong>15 May 2022</strong>
                                        </div>
                                        <div>
                                            Total <br/>
                                            <strong>Rs. 10,000,000/-</strong>
                                        </div>
                                        <div className='text-right'>
                                            Order # 403-3455654-4289913 <br/>
                                        </div>
                                    </div>
                                    <div className='order_body'>
                                        <div>
                                            <img src={product_img} alt="" />
                                        </div>
                                        <div>
                                            <h4>Computer controlled mobile device</h4>
                                            <h5>Non-Exclusive License - 5 year</h5>
                                        </div>
                                    </div>
                                </div> */}
                        </Container>
                    </TabPane>
                    {/* <TabPane tabId="2" className='p-4'>
                    <Container>
                        <span className='txt-big'>01 orders</span> Cancelled in last 12 months
                            
                                <div className='order-item'>
                                    <div className='order_head'>
                                        <div>
                                            Order Placed<br/>
                                            <strong>10 May 2022</strong>
                                        </div>
                                        <div>
                                            Order Fulfilled<br/>
                                            <strong>15 May 2022</strong>
                                        </div>
                                        <div>
                                            Total <br/>
                                            <strong>Rs. 10,000,000/-</strong>
                                        </div>
                                        <div className='text-right'>
                                            Order # 403-3455654-4289913 <br/>
                                        </div>
                                    </div>
                                    <div className='order_body'>
                                        <div>
                                            <img src={product_img} alt="" />
                                        </div>
                                        <div>
                                            <h4>Computer controlled mobile device</h4>
                                            <h5>Non-Exclusive License - 5 year</h5>
                                        </div>
                                    </div>
                                </div>
                    </Container>
                    </TabPane> */}
                </TabContent>
            </Container>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Orders);