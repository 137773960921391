import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";

const WhyUs = () => {
  return (
    <Fragment>
      <Row>
        <Col xs={{ size: 12, order: 2,}}  sm={9} md={9} lg={9} xl={{ size: 9, order: 1,}}>
          <Row>
            <Col sm={12} md={5} lg={5} xl={5}>
              <ul className="whyus">
                <li>
                  <div className="hexagon"></div>
                  <div className="number">1</div>
                  <div className="heading">
                    <h6>
                      Connecting with the <br />
                      Right People/Business
                    </h6>
                  </div>
                </li>
                <li>
                  <div className="hexagon"></div>
                  <div className="number">2</div>
                  <div className="heading">
                    <h6>
                      Analysis of
                      <br />
                      Market and Demand
                    </h6>
                  </div>
                </li>
                <li>
                  <div className="hexagon"></div>
                  <div className="number">3</div>
                  <div className="heading">
                    <h6>
                      Identifying
                      <br />
                      the potential IP
                    </h6>
                  </div>
                </li>
                <li>
                  <div className="hexagon"></div>
                  <div className="number">4</div>
                  <div className="heading">
                    <h6>
                      Future
                      <br />
                      IP generation
                    </h6>
                  </div>
                </li>
                <li>
                  <div className="hexagon"></div>
                  <div className="number">5</div>
                  <div className="heading">
                    <h6>
                      Enforcement of
                      <br />
                      IP rights
                    </h6>
                  </div>
                </li>
              </ul>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6} className="d-flex align-items-end">
              An invention is a result of industrious thinking and thorough hard
              work. Invention + business acumen can help bring to life the
              commercialization of the patent/ design/trademark. A business
              needs an idea and an idea needs a business. Honeybeezz extends the
              hand that can bring together the two.
            </Col>
          </Row>
        </Col>

        <Col xs={{ size: 12, order: 1,}} sm={3} md={3} lg={3} xl={3} className="d-flex align-items-end">
          <h2 className="main_heading mb-5">Why Us?</h2>
        </Col>
      </Row>
    </Fragment>
  );
};
export default WhyUs;
