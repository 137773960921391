import * as Types from '../../Constents/actionTypes';
  
const auth = (state = {}, action) => {
    //console.log(`state in auth is :`,state);
    switch (action.type) {
      
    case Types.REGISTRATION: 
      return {
        ...state,
        isOpen:action.isOpen
      }
    case Types.FORGOT_PASSWORD: 
      return {
        ...state,
        isOpenForgotPassword:action.isOpen
      } 
    case Types.USER_FORGOT_PASSWORD: 
    return {
      ...state,
      OptSend:action.OtpSend
    } 
    case Types.USER_FORGOT_PASSWORD_ERROR: 
    return {
      ...state,
      forgotPswdError:action.forgotPswdError
    }
    case Types.RESET_PASSWORD: 
    return {
      ...state,
      passwordReset:action.payload
    } 
    case Types.CHANGE_PASSWORD: 
    return {
      ...state,
      passwordChange:action.passwordChange
    }    
    case Types.ADD_NEW_USER:
      return{
        ...state,
        addedUser:action.addedUser,
        userData:action.userData
      }
    case Types.ADD_NEW_USER_ERROR:
      return{
        ...state,
        errorMsg: action.exception
      } 
    case Types.GET_USER_DETAIL:
      return{
        ...state,
        userDetails: action.userDetails
      } 
    case Types.UPDATE_USER_DETAIL:
      return{
        ...state,
        updateUserDetails: action.updateUserDetails
      }   
    case Types.PAGE_DATA:
      return{
        ...state,
        pageData: action.pageData
      }
    case Types.GET_TEAM:
      return{
        ...state,
        teamData: action.teamData
      } 
    case Types.GET_CLIENT:
      return{
        ...state,
        clientData: action.clientData
      }     
    case Types.RESEND_EMAIL:
      return{
        ...state,
        mailResend:action.mailResend
      }
    case Types.GET_ORDERS:
      return{
        ...state,
        orderData: action.orderData
      }      
    case Types.CLEAR:
      return{
        ...state,
        addedUser:undefined
      }     
      default:
        return state;
    }
};
export default auth;