import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { connect } from "react-redux";
import './brands.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import textAreaLeft from '../../Assets/input_1.png'
import textAreaRight from '../../Assets/input_2.png'
import { PROTECT_BRAND } from '../../Store/actions/submitForm'
import {toast} from 'react-toastify'

const mapStateToProps = (state) => ({
    brandProtected: state.submitForm.brandProtected,
    userToken:state.auth.userToken
});
const mapDispatchToProps = (dispatch) => ({
    PROTECT_BRAND: (payload) => dispatch(PROTECT_BRAND(payload))
});


const ProtectBrand = (props) => {

    const [newFormData, setNewFormData] = useState({});
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(()=>{
        if(props.brandProtected){
            setIsSuccess(true)
            setTimeout(() => {
                closeForm()
                setIsSuccess(false)
            }, 3000);
        }
        // eslint-disable-next-line
    }, [props.brandProtected])

    const closeForm = () => {
        props.setIsOpen(false)
    }

    const setFormData = (e) => {
        const { name, value } = e.target
        const formData = newFormData
        formData[name] = value
        setNewFormData(
            formData
        )
    }
    const protectBrandData = () => {
        const {ProposedTrademark, details, countryinterested} = newFormData
        if(!details || !countryinterested || !ProposedTrademark){
            toast.error("Please fill All Field")
            return
        }else{
        const payload = {
            ...newFormData,
            userId:props.userToken.userId,
            adminName:"Info",
            adminEmail:"info@honeybeezz.co"
          }
          // console.log(payload);
          props.PROTECT_BRAND(payload)
        }
    }
    return (
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen}
            // toggle={()=>props.REGISTRATION(false)}
            >
                {isSuccess ?
                <Fragment>
                    <div className='isSuccess'>
                    <h2>Thank you</h2>
                    <h4>for sharing your requirements with us.<br/>
                        We will contact you shortly.</h4>
                    </div>
                </Fragment> :<Fragment>
                    <ModalHeader toggle={closeForm}>
                        Protect your Brand
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs={{ size: 8, offset: 2 }}>
                                <Row>
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={leftImg} alt="" className='inputLeft' />
                                            <Input type='text' placeholder='Proposed Trademark(if any)*' className='inputField' name="ProposedTrademark" onChange={setFormData} />
                                            <img src={rightImg} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={leftImg} alt="" className='inputLeft' />
                                            <Input type='text' placeholder='Product/Service details*' className='inputField' name="details" onChange={setFormData} />
                                            <img src={rightImg} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={leftImg} alt="" className='inputLeft' />
                                            <Input type='text' placeholder='Country interested*' className='inputField' name="countryinterested" onChange={setFormData} />
                                            <img src={rightImg} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={leftImg} alt="" className='inputLeft' />
                                            <Input type='text' placeholder='Use of Mark(if any)' className='inputField' name="UseofMark" onChange={setFormData} />
                                            <img src={rightImg} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='inputWrapper'>
                                            <img src={textAreaLeft} alt="" className='inputLeft' />
                                            <Input type='textarea' placeholder='Comments/Message' className='inputarea' name="comments" onChange={setFormData} />
                                            <img src={textAreaRight} alt="" className='inputRight' />
                                        </div>
                                    </Col>
                                    <Col xs={12} className="text-center mt-4">
                                        <Button className='cta_btn' onClick={protectBrandData}> <h5>Go</h5> </Button>
                                    </Col>
                                    <Col xs={12} className="mt-4 mendet">
                                        * mark field are mendetory
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                </Fragment>}
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProtectBrand);