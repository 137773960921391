import * as Types from '../../Constents/actionTypes'
import Category from '../../Models/category'


export const GET_TECH_CATEGORY_LIST = ()=> async (dispatch)=>{
try {
    const response = await Category.getTechCategoryList()
    dispatch({
        type:Types.GET_TECH_CATEGORY_LIST,
        techCategoryList:response
    })
} catch (error) {
    dispatch({
        type:Types.GET_TECH_CATEGORY_LIST_ERROR,
        error
    })
}
}

export const GET_BRAND_CATEGORY_LIST = ()=> async (dispatch)=>{
    try {
        const response = await Category.getBrandCategoryList()
        dispatch({
            type:Types.GET_BRAND_CATEGORY_LIST,
            brandCategoryList:response
        })
    } catch (error) {
        dispatch({
            type:Types.GET_BRAND_CATEGORY_LIST_ERROR,
            error
        })
    }
    }