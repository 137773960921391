
let _API_URL
let _IMG_URL
export const ENV_API_URL = process.env.REACT_APP_BASEURL
export const ENV_IMG_URL = process.env.REACT_APP_IMGURL

export const API_REQUESTS = {
    LOGIN : 'user/login',
    USERS : 'users',
    TECHNOLOGIS: 'technologis',
    BRANDS:'brands',
    TECHNOLOGY_PUBLISH:'/technology/publish',
    TECHNOLOGY_PROTECT:'/technology/protect',
    TECHNOLOGY_SALE:'/technology/sale',
    BRAND_PUBLISH:'/brand/publish',
    BRAND_PROTECT:'/brand/protect',
    BRAND_SALE:'/brand/sale',
    BRAND_PRODUCT:'find/brands/product',
    TECH_PRODUCT:'find/technology/product'
}

const reactEnv = process.env.REACT_APP_ENVIROMENT;

// console.log(reactEnv)
// console.log(ENV_IMG_URL)

if(reactEnv === "LOCAL") {
    _API_URL = `${ENV_API_URL}`;
    _IMG_URL = `${ENV_IMG_URL}`;
} else if (reactEnv === "STAGE"){
    _API_URL = `${ENV_API_URL}`;
    _IMG_URL = `${ENV_IMG_URL}`;
}else if (reactEnv === "LIVE") {
    _API_URL = `${ENV_API_URL}`;
    _IMG_URL = `${ENV_IMG_URL}`;
}else if (reactEnv === "DEVELOP") {
    _API_URL = `${ENV_API_URL}`;
    _IMG_URL = `${ENV_IMG_URL}`;
}


export const API_URL = _API_URL
export const IMG_URL = _IMG_URL

export const VALIDATE_REGEX = {
    // email: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
    email: /[a-zA-Z0-9._]*@[a-zA-Z0-9.-]*\.[a-z]*/,
    phone: /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/im
}