import React, { Fragment} from 'react'
import { Container } from 'reactstrap';
import { connect } from "react-redux";


const mapStateToProps = (state) => ({
    
});



const VerifyEmail =(props)=>{

    
    return(
        <Fragment>
                <Container className='email_verify'>
                    <div className='contantWrapper'>
                        <h2>Thank you for verifying! </h2>
                        <h5>You are good to go.<br/>Go to &nbsp; 
                        <a href='/' className='link_btn'>signin</a></h5>
                    </div>
                </Container>
        </Fragment>
    )
}
export default connect(mapStateToProps)(VerifyEmail);