import React, { Fragment } from 'react'
import { Col, Container, Row } from 'reactstrap';
import Carousel from './carousel';
import section_img from '../../Assets/carousel-left.png'
import './home.scss'
import WhyUs from './whyUs';
import Strategical from './strategical';
import Categories from './categories';
import Clients from './client';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShowCase from '../Showcase';
import { connect } from "react-redux";
import {GET_CLIENT} from '../../Store/actions/users'
import { useEffect } from 'react';
const mapStateToProps = (state) => ({
    clientData:state.users.clientData,
    techProductList: state.Product.techProductList,
});

const mapDispatchToProps = (dispatch) => ({
    GET_CLIENT:()=>dispatch(GET_CLIENT())
});

const Home =(props)=>{
    useEffect(()=>{
        props.GET_CLIENT()
         // eslint-disable-next-line
    },[])
    const {clientData}= props
    return(
        <Fragment>
            <Container>
                <section>
                    <Row>
                        <Col sm="6" lg="4" className='d-none d-sm-block'><Carousel/></Col>
                        <Col xs="12" sm="6" xl="8"><img src={section_img} alt="Honey Beez" className='img-fluid mt-4'/></Col>
                        <Col xs={12} sm="6" lg="4" className='text-center d-sm-none mt-3'><Carousel/></Col>
                    </Row>
                </section>
                <section className='sectionWhyUs'>
                    <WhyUs/>
                </section>
                <section className='strategical'>
                    <Strategical/>
                </section>
                <section className='categories'>
                        <Categories/>
                </section>
                <section className='client'>
                            <Clients clientData={clientData}/>
                </section>
                <section className='showcase'>
                            <ShowCase/>
                 </section>
            </Container>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);