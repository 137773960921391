import React, { Fragment} from 'react'
import { Row, Col } from 'reactstrap';

const Strategical =()=>{
    return(
        <Fragment>
            <Row>
                <Col>
                    <h1 className='heading'>Strategical Approach</h1>
                    <p className='text-center mt-5 mb-3 fnt-22'>Systematic Approach, methodologies, and analysis for monetization</p>
                </Col>
            </Row>
            <Row className='strategical_inner'>
                <Col sm={4} md={4} lg={5} xl={4} className="haxagon-wrapper">
                    {/* <div className='hexagon2'></div> */}
                    <div className='strategical_content'>
                        <h4>Current Market Trends</h4>
                        <ul>
                            <li>Identifying the size of the market and nature of business</li>
                            <li>Analyzing the size of the market</li>
                            <li>Finding the relevant technology/IP and its capitalization</li>
                            <li>Analyzing Commercial Value of the IP rights with respect to the current business and Market trend</li>
                        </ul>
                    </div>
                </Col>
                <Col sm={4} md={4} lg={5} xl={4} className="haxagon-wrapper">
                {/* <div className='hexagon2'></div> */}
                    <div className='strategical_content'>
                        <h4>Finding <br/> the potential IP</h4>
                        <ul>
                            <li>Finding potential IP matching the current market requirements</li>
                            <li>Finding potential patents which will emerge as commercial value</li>
                            <li>Strategic Ideas for the Future IP generation</li>
                        </ul>
                    </div>
                </Col>
                <Col sm={4} md={4} lg={5} xl={4} className="haxagon-wrapper">
                {/* <div className='hexagon2'></div> */}
                    <div className='strategical_content'>
                        <h4>Licensing <br/>  Model</h4>
                        <ul>
                            <li>Finding potential licensees, collaborative partners, and Angel Investors</li>
                            <li>Connecting with Entrepreneurs, collaborative partners</li>
                            <li>Helping in negotiations in licensing</li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}
export default Strategical;