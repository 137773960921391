import Axios from "./Axios";
import {  API_URL, API_REQUESTS } from '../Helpers/constant'

const __DEV__ = document.domain === 'localhost'
function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}
class Products extends Axios {
    
    static async getAllTechProductList() {
        const USER_URL = `${API_URL}get/technology/products`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL).then((listData)=> {
                if(listData.data) {
                    return resolve(listData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }
    static async getAllBrandProductList() {
        const USER_URL = `${API_URL}get/brands/products`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL).then((listData)=> {
                if(listData.data) {
                    return resolve(listData.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }
    static async getTechProductList(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.TECH_PRODUCT}`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL,payload).then((productDate)=> {
                if(productDate.data) {
                    return resolve(productDate.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }
    static async getBrandProductList(payload) {
        const USER_URL = `${API_URL}${API_REQUESTS.BRAND_PRODUCT}`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL,payload).then((productDate)=> {
                if(productDate.data) {
                    return resolve(productDate.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }




    
    static async displayRazorpay (payload) {
		const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        
		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}
        const {userId, description,image, orderDate, price, productId, productName, productType} = payload
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"productId":productId,
				"userId":userId,
				"productType":productType,
				"amount":price,
				"orderDate":orderDate
			})
            // body: JSON.stringify(payload)
		};
		
		const backendResponse = await fetch('https://honeybeezz.co/api/order/do/payment', requestOptions).then((t) =>
			t.json()
		)

		const data = backendResponse.DATA;

		// console.log("backend se data laya :: ", data);
		let finalResponse

		const options = {
			key: __DEV__ ? 'rzp_live_o9Ka98RvvnbEYI' : 'rzp_live_o9Ka98RvvnbEYI',
			currency: data.currency,
			amount: data.amount.toString(),
			order_id: data.orderId,
			name: productName,
			description: description,
			image: image,
			prefill: {
				email: 'example@example.com',
				phone_number: '9899999999'
			},
            handler: function (response) {
				// console.log("after payment respons :: ", response);
                finalResponse = response
				if(response.razorpay_payment_id && response.razorpay_order_id && response.razorpay_signature) {
                    return response
				}
			}
		}
		const paymentObject = new window.Razorpay(options)
		paymentObject.open()

        return finalResponse
	}


    static async makeAnOffer(payload) {
        const USER_URL = `${API_URL}offer`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL,payload).then((productDate)=> {
                if(productDate.data) {
                    return resolve(productDate.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

    static async sendEnquiry(payload) {
        const USER_URL = `${API_URL}inquiry`
        return new Promise((resolve,reject)=>{
            this.axios.post(USER_URL,payload).then((productDate)=> {
                if(productDate.data) {
                    return resolve(productDate.data.DATA)
                }
            })
            .catch(exception => {
                return reject(exception)
            })
        })
    }

}


export default Products;