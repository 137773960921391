import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import{Navbar} from 'reactstrap'
import AppLogo from "../AppLogo";
import AppNav from "../AppNav";
import {LOGOUT} from '../../Store/actions/auth'
import {useNavigate } from 'react-router-dom';
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  LOGOUT:()=>dispatch(LOGOUT())
});
const AppHeader = (props) => {
  const [toggle, setToggle] = useState(false)
  // const [stickyClass, setStickyClass] = useState('');
  let navigate = useNavigate();
  const menuFunc =()=>{
    setToggle(!toggle)
  }
  const logout = ()=>{
    props.LOGOUT()
    navigate("/");
  }

  // useEffect(() => {
  //   window.addEventListener('scroll', stickNavbar);

  //   return () => {
  //     window.removeEventListener('scroll', stickNavbar);
  //   };
  // }, []);

  // const stickNavbar = () => {
  //   if (window !== undefined) {
  //     let windowHeight = window.scrollY;
  //     windowHeight > 5 ? setStickyClass('sticky') : setStickyClass('');
  //   }
  // };

  return <Fragment>
      <Navbar
    expand="md"
    light
    className="navbar-fixed-top"
    // className={`${stickyClass}`}
  >
      <AppLogo/>
      <AppNav toggle={toggle} menuFunc={menuFunc} logout={logout}/>
  </Navbar>
  </Fragment>
};
export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
