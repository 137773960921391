import auth from './auth'
import users from './users'
import submitForm from './submitForm'
import Product from './product'
import Category from './category'
const reduser = {
    auth,
    users,
    submitForm,
    Product,
    Category
}
export default reduser