import React, { Fragment, useState} from 'react'
import { Row, Col, Input, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { connect } from "react-redux";
// import './registration.scss'
import rightImg from '../../Assets/input-right.png'
import leftImg from '../../Assets/input-left.png'
import {CHANGE_PASSWORD, CLEAR_RESET} from '../../Store/actions/users'

import { useEffect } from 'react';
// import Captcha from './Captcha'

const mapStateToProps = (state) => ({
    passwordChange:state.users.passwordChange
});
const mapDispatchToProps = (dispatch) => ({
    CHANGE_PASSWORD:(payload)=>dispatch(CHANGE_PASSWORD(payload)),
    CLEAR_RESET:()=> dispatch(CLEAR_RESET())
  });

  
const ChangePassword =(props)=>{
    
    const [errMessage, setErrMessage] = useState("")
    const [successMsg, setSuccessMsg] = useState("")
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState(""); 
    

    

    useEffect(()=>{
        if(props.passwordChange) {
            setSuccessMsg(props.passwordChange)
            props.closeForm()
         }
          // eslint-disable-next-line
    },[props.passwordChange])
    

    
    const setData = e =>{
        const {name, value} = e.target
        if(name === 'oldPassword'){
            setOldPassword(value)
        }
        if(name === 'newPassword'){
            setNewPassword(value)
        }
        if(name === 'confirmPassword'){
            setConfirmPassword(value)
        }
    }
    
    
    const doChangePassword =()=>{
        if(newPassword !== confirmPassword){
            setErrMessage("Password not Match")
            return
        }
        const payload = {
            email: props.userDetails.email,
            oldPassword: oldPassword,
            newPassword: newPassword
          }
          props.CHANGE_PASSWORD(payload)
    }
    return(
        <Fragment>
            <Modal
                size="md"
                isOpen={props.isOpen} 
            >
                <Fragment>
                <ModalHeader toggle={props.closeForm}>
                    Change Password 
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={{size:8, offset:2}}>
                            <Row>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='password' placeholder='Old Password' className='inputField' name="oldPassword" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='password' placeholder='New Password*' className='inputField' name="newPassword" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='inputWrapper'>
                                        <img src={leftImg} alt="" className='inputLeft'/>
                                        <Input type='password' placeholder='Confirm Password*' className='inputField' name="confirmPassword" onChange={setData}/>
                                        <img src={rightImg} alt="" className='inputRight'/>
                                    </div>
                                </Col>
                                <Col xs={12} className="text-center mt-4">
                                    <Button className='cta_btn' onClick={() => doChangePassword()}> <h5>Submit</h5> </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    { (errMessage) ? (
                            <div className="mt-4 alert alert-danger text-center">{errMessage} * </div>
                        ): null
                        }
                        { (successMsg) ? (
                            <div className="mt-4 alert alert-success text-center">{successMsg} * </div>
                        ): null
                        }
                </ModalBody>
                </Fragment>
                
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);