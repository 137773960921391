import React, { Fragment } from "react";
import { connect } from "react-redux";
// import Logo from '../../Assets/honeybeez_logo.png'
import LogoSvg from '../../Assets/logo.svg'
import{NavbarBrand} from 'reactstrap'
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});
const AppLogo = (props) => {
  return <Fragment>
      <NavbarBrand href="/">
      <img src={LogoSvg} alt="HoneyBeez"/>
    </NavbarBrand>
  </Fragment>
};
export default connect(mapStateToProps, mapDispatchToProps)(AppLogo);
