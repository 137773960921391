import React, { Fragment } from 'react'
import { useNavigate } from "react-router-dom";
import { Row, Col, Container} from 'reactstrap';
import { connect } from "react-redux";
import './brands.scss'
import { GET_BRAND_PRODUCT_LIST } from '../../Store/actions/product';
import {GET_BRAND_CATEGORY_LIST} from '../../Store/actions/category'
import { useEffect } from 'react';


const mapStateToProps = (state) => ({
    techSale: state.submitForm.techSale,
    userToken:state.auth.userToken,
    brandCategoryList:state.Category.brandCategoryList
});
const mapDispatchToProps = (dispatch) => ({
    GET_BRAND_PRODUCT_LIST:(cateId)=> dispatch(GET_BRAND_PRODUCT_LIST(cateId)),
    GET_BRAND_CATEGORY_LIST:()=>dispatch(GET_BRAND_CATEGORY_LIST())
});


const AllBrands = (props) => {
    let navigate = useNavigate();
    // const [isOpen, setIsOpen] = useState(false)
    // const [techName, setTechName]  = useState("")
    // const [modalData, setModalData] = useState([])
    // const [activeTab, setActiveTab] = useState(0)

    // const toggleTab = (tabId)=>{
    //     setActiveTab(tabId)
    // }


    // const closeForm =()=>{
    //     setIsOpen(false)
    // }
    useEffect(()=>{
        props.GET_BRAND_CATEGORY_LIST()
        // eslint-disable-next-line
    },[])

    // const openModal = (name, data)=>{
    //     setTechName(name)
    //     setModalData(data)
    //     setIsOpen(true)
    // }

    const goToProductList = (type, cateId)=>{
        const payload = {
            "id" : cateId,
            "type":type
        }
        props.GET_BRAND_PRODUCT_LIST(payload)
        navigate('/brandproductList')
    }
    // console.log(modalData)
    const {brandCategoryList} = props
    return (
        <Fragment>
            <Container>
            <Row>
                <Col sm={4} md={{offset: 3,  size: 7 }} lg={{offset: 3,  size: 7 }} xl={{offset: 3,  size: 7}}>
                    <h2 className='brand_categories_head'>Brands</h2>
                    <ul className='brand_categories_list'>
                        {brandCategoryList && brandCategoryList.length && brandCategoryList.map((item, index)=>{
                            // return <li key={index} onClick={()=>openModal(item.name, item.subCategory)}>{item.name}</li>
                            return <li key={index} onClick={()=>goToProductList("CATEGORY", item._id)}>{item.name.toLowerCase()}</li>
                        })}
                    </ul>
                </Col>
            </Row>
            </Container>
            {/* <Modal size="lg" isOpen={isOpen} className="brand_cate_modal">
                <ModalHeader toggle={closeForm}>
                    {techName}
                </ModalHeader>
                <ModalBody>
                
                {modalData && modalData[0] && modalData[0].technologies && modalData[0].technologies.length > 0 ? 
                <Nav tabs>   
                {modalData.map((item, index)=>{ return <Fragment>
                    <NavItem key={index}>
                    <NavLink className={activeTab === index?"active":""} onClick={() => toggleTab(index) }>
                        {item.name}
                    </NavLink>
                    </NavItem>
                    </Fragment>
                })}
                </Nav>
                : 
                <ul className='sub_categories_list'>   
                {modalData.map((item, index)=>{
                    return <li key={index} onClick={goToProductList}>{item.name}</li>
                })}
                </ul>}
                {modalData && modalData[0] && modalData[0].technologies && modalData[0].technologies.length > 0? 
                    <TabContent activeTab={activeTab} className="categories_wrapper">
                        {modalData.map((item, index)=>{
                        return <Fragment>
                            <TabPane tabId={index} className='p-4'>
                                <ul className='categories_list'>
                                {item.technologies.map((subItem, index)=>{
                                    return <li key={index} onClick={goToProductList}>{subItem.name}</li>
                                })}
                                </ul>
                            </TabPane> 
                            </Fragment>
                        })}
                </TabContent>
                :null}
                      

                 
                </ModalBody>  
            </Modal> */}
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(AllBrands);