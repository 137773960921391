import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
// import { Facebook, Instagram, Twitter } from '../../Components/Icons';
import Protecttechnology from "../../Components/Technology/protecttechnology";
const mapStateToProps = (state) => ({
  pageData:state.users.pageData
});

const mapDispatchToProps = (dispatch) => ({});
const AppFooter = (props) => {
  const [protect, setProtect] = useState(false)
  const [defaultData, setDefaultData] = useState('')
    useEffect(()=>{
        if(props.pageData){
            let data = props.pageData.find(oneData=>{
                return oneData.key === 'contact_us'
            })
            setDefaultData(data.value)
        }
    }, [props.pageData])
  const protectTechnology = ()=>{
    setProtect(!protect)
}
  return <Fragment>
    <footer>
      <Container>
        <Row>
          <Col xl={10}>
              <Row>
                  <Col xl={2}>
                    <Row>
                      <Col><strong>About Us</strong></Col>
                      <Col>
                        <ul>
                          <li><a href="/about">Why Us</a></li>
                          <li><a href="/about">Team</a></li>
                          <li><a href="/about">Partners</a></li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={3}>
                  <Row>
                      <Col xl={4}><strong>Contact Us</strong></Col>
                      <Col xl={8}>
                        <ul>
                          <li>
                          <div dangerouslySetInnerHTML={{__html:defaultData && defaultData}}></div>
                          </li>
                          {/* <li>+44 345 678 903</li>
                          <li><a href="mailto:honeybeez@mail.com">honeybeez@mail.com</a></li> */}
                          <li><a href="#reg" onClick={protectTechnology}>Register your IP</a></li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={3}>
                    <Row>
                      <Col xl={5}><strong>IP Marketplace</strong></Col>
                      <Col>
                        <ul>
                          <li><a href="/technology">Technologies</a></li>
                          <li><a href="/brands">Brands</a></li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={2}>
                    <ul>
                      <li><a href="/privacy"><strong>PRIVACY POLICY</strong></a></li>
                      <li><a href="/terms"><strong>TERMS OF USE</strong></a></li>
                      <li><a href="/faq"><strong>FAQ</strong></a></li>
                    </ul>
                  </Col>
              </Row>
          </Col>
          <Col xl={2} className="social_link">
            
            {/* <a href="https://twitter.com/" target="_blank" rel="noreferrer"><Twitter/></a>
            <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><Facebook/></a>
            <a href="https://www.instagram.com/" target="_blank" rel="noreferrer"><Instagram/></a> */}
          </Col>
        </Row>
      </Container>
      <Protecttechnology isOpen={protect} setIsOpen={setProtect}/>
    </footer>
  </Fragment>
};
export default connect(mapStateToProps, mapDispatchToProps)(AppFooter);
