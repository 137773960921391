import * as Types from '../../Constents/actionTypes';
  
const Product = (state = {}, action) => {
    switch (action.type) {
      
    case Types.GET_TECH_CATEGORY_LIST: 
      return {
        ...state,
        techCategoryList:action.techCategoryList
      }
    case Types.GET_BRAND_CATEGORY_LIST: 
      return {
        ...state,
        brandCategoryList:action.brandCategoryList
      }  
      default:
        return state;
    }
};
export default Product;